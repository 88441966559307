@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.product-details-card-image {
  width: 500px;

  &-title {
    padding-bottom: 4px;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: #1b1b20;
    font-weight: 420;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
  }
}

.tenant-details-form {
  max-width: 500px;

  &-switch {
    display: flex;
    align-items: baseline;
  }
}

.details-wrapper {
  background-color: white;
  padding: 30px 40px;
  min-height: var(--content-height);
}
