@import "../../../scss/variables/colors";

.actions-cell {
  display: flex;
  justify-content: flex-end;
  opacity: 1;

  :global {
    .ant-menu {
      border-radius: 12px;
      box-shadow:
        0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0px 6px 16px rgba(0, 0, 0, 0.08),
        0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      position: absolute;
      z-index: 1;
      top: 40px;
    }

    .ant-btn-compact-first-item {
      display: none;
    }

    .ant-btn-compact-last-item {
      border-start-start-radius: 8px !important;
      border-end-start-radius: 8px !important;
    }
  }
}

:global {
  .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .anticon {
    margin-right: 8px;
  }
}
