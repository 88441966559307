@import "../../../../../common/ui/src/lib/scss/variables/colors";

.cards-panel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 24px;
  & > * {
    width: 100%;
    max-width: calc(50% - 8px);
    min-width: 400px;
    box-sizing: border-box;
  }
}

.add-var {
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  color: $text-red;
  padding: 0 6px;
}

