@import "../../../../../common/ui/src/lib/scss/variables/colors";

.phase-details-form {
  display: grid;
  gap: 24px;

  &-info {
    background: $main-black-13;
    padding: 16px;
    gap: 16px;
    display: grid;
    border-radius: 16px;
  }

  .empty-pages {
    font-weight: 325;
    font-size: 14px;
    line-height: 17px;
    color: $text-dark-gray;
  }

  &-inputs {
    display: flex;
    gap: 16px;

    .phase-input {
      width: 50%;
      margin-bottom: 0;
    }
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $text-blue;
  }

  &-pages {
    background-color: $main-black-13;
    padding: 16px;
    border-radius: 16px;
    gap: 20px;
    display: grid;
  }

  &-buttons {
    display: flex;
    grid-gap: 24px;
    gap: 24px;

    .button {
      width: auto;
      padding: 0;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 20px;
      color: $text-red;

      display: flex;
      gap: 8px;
    }
  }

  .submit-button {
    width: 236px;
  }

  .draggable-phases {
    display: grid;
    gap: 24px;
  }
}
