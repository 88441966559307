@import "../../../../../../../common/ui/src/lib/scss/variables/colors";

.ni-loyalty-program {
  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-red;
  }
}
