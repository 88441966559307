@import "../../scss/variables/colors";

.switched-inputs {
  gap: 4px !important;

  :global {
    .limits-block-body {
      .ant-row {
        margin-bottom: 16px;
        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
        &:not(.ant-form-item-row) {
          align-items: flex-end !important;
        }
        &.ant-form-item-row {
          align-items: flex-start !important;
        }

        .ant-col {
          &.ant-form-item-label {
            padding-bottom: 12px !important;

            > label {
              text-wrap: nowrap;
              font-size: 13px !important;

              > span {
                margin-inline-start: 0px !important;
              }
            }
            ::after {
              all: unset !important;
            }
          }
        }
        .ant-col {
          margin-bottom: 0 !important;

          &:first-of-type {
            .ant-space {
              text-wrap: nowrap;
            }
          }
        }
      }
      .ant-space {
        .ant-space-item {
          .switch-title-container > div {
            div {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
