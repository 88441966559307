@import "../../scss/variables/colors";

.modal-invite {
  width: 525px !important;

  :global(.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger)) {
    margin-left: 0;
  }

  :global(.ant-modal-header) {
    padding-bottom: 8px;
  }

  :global(.ant-modal-body) {
    padding-top: 8px;
  }

  :global(.ant-modal-footer) {
    padding-top: 0;
  }

  :global(.ant-upload.ant-upload-drag .ant-upload-drag-container) {
    display: grid;
    gap: 12px;
  }

  :global(.dragger-title) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    p.ant-upload-text {
      margin: 0 !important;
    }

    .button {
      height: 23px;
      width: 23px;
      svg {
        height: 23px;
        width: 23px;
        color: $main-black-6;
      }
    }
  }

  &-footer {
    display: inline-flex;
    gap: 20px;

    &.ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 0 !important;
    }
  }

  &-button {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    width: 88px;
  }
}

.modal-import {
  width: 525px !important;

  :global(.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger)) {
    margin-left: 0;
  }

  :global(.ant-modal-header) {
    padding-bottom: 8px;
  }

  :global(.ant-modal-body) {
    padding-top: 8px;
  }

  :global(.ant-modal-footer) {
    padding-top: 0;
  }

  :global(.ant-upload.ant-upload-drag .ant-upload-drag-container) {
    display: grid;
    gap: 12px;
  }

  :global(.dragger-title) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    p.ant-upload-text {
      margin: 0 !important;
    }

    .button {
      height: 23px;
      width: 23px;
      svg {
        height: 23px;
        width: 23px;
        color: $main-black-6;
      }
    }
  }

  &-footer {
    display: inline-flex;
    gap: 20px;

    &.ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 0 !important;
    }
  }

  &-button {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    width: 88px;
  }
}

.modal-export {
  width: 525px !important;

  :global(.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger)) {
    margin-left: 0;
  }

  :global(.ant-modal-header) {
    padding-bottom: 8px;
  }

  :global(.ant-modal-body) {
    padding-top: 8px;
  }

  :global(.ant-modal-footer) {
    padding-top: 0;
  }

  :global(.ant-upload.ant-upload-drag .ant-upload-drag-container) {
    display: grid;
    gap: 12px;
  }

  :global(.dragger-title) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    p.ant-upload-text {
      margin: 0 !important;
    }

    .button {
      height: 23px;
      width: 23px;
      svg {
        height: 23px;
        width: 23px;
        color: $main-black-6;
      }
    }
  }

  &-footer {
    display: inline-flex;
    gap: 20px;

    &.ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 0 !important;
    }
  }

  &-button {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    width: 88px;
  }
}

.modal-confirm {
  width: 525px !important;

  :global(.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger)) {
    margin-left: 0;
  }

  :global(.ant-modal-header) {
    padding-bottom: 8px;
  }

  :global(.ant-modal-body) {
    padding-top: 8px;
  }

  :global(.ant-modal-footer) {
    padding-top: 0;
  }

  :global(.ant-upload.ant-upload-drag .ant-upload-drag-container) {
    display: grid;
    gap: 12px;
  }

  :global(.dragger-title) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    p.ant-upload-text {
      margin: 0 !important;
    }

    .button {
      height: 23px;
      width: 23px;
      svg {
        height: 23px;
        width: 23px;
        color: $main-black-6;
      }
    }
  }

  &-footer {
    display: inline-flex;
    gap: 20px;

    &.ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 0 !important;
    }
  }

  &-button {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    width: 88px;
  }
}

.modal-warning {
  width: 525px !important;

  :global(.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger)) {
    margin-left: 0;
  }

  :global(.ant-modal-header) {
    padding-bottom: 8px;
  }

  :global(.ant-modal-body) {
    padding-top: 8px;
  }

  :global(.ant-modal-footer) {
    padding-top: 0;
  }

  :global(.ant-upload.ant-upload-drag .ant-upload-drag-container) {
    display: grid;
    gap: 12px;
  }

  &-footer {
    display: inline-flex;
    gap: 20px;

    &.ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 0 !important;
    }
  }

  &-button {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    width: 88px;
  }
}

.modal-validation {
  width: 1216px !important;

  .form-items {
    display: flex;
    gap: 24px;

    :global(.ant-form-item) {
      width: 50%;
    }
  }

  :global(.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger)) {
    margin-left: 0;
  }

  :global(.ant-modal-header) {
    padding-bottom: 8px;
  }

  :global(.ant-modal-body) {
    padding-top: 8px;
  }

  :global(.ant-modal-footer) {
    padding-top: 0;
  }

  :global(.ant-upload.ant-upload-drag .ant-upload-drag-container) {
    display: grid;
    gap: 12px;
  }

  :global(.dragger-title) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    p.ant-upload-text {
      margin: 0 !important;
    }

    .button {
      height: 23px;
      width: 23px;
      svg {
        height: 23px;
        width: 23px;
        color: $main-black-6;
      }
    }
  }

  &-footer {
    display: inline-flex;
    gap: 20px;

    &.ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 0 !important;
    }
  }

  &-button {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    width: 88px;
  }
}

.modal-select-option {
  z-index: 100;
  width: fit-content;
  height: 50%;
  border-radius: 8px;

  .ant-modal {
    max-width: 65%;
  }

  button .ant-modal-close {
    position: absolute;
    left: 0;
  }

  .ant-modal-close {
    left: 0;
    display: block;
  }

  :global(.ant-modal-content) {
    width: 100%;
    z-index: 101;
    position: relative;
    display: block;
    border-radius: 8px;
  }

  :global(.ant-form-item) {
    height: 30px;
    :global(.ant-row) {
      height: 30px;
      :global(.ant-form-item-control) {
        height: 30px;
      }
    }
  }

  :global(.ant-table-cell) {
    height: 24px;
    p {
      margin-bottom: 0;
    }
  }

  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-left: 0;
  }

  .ant-modal-header {
    padding: 16px 24px 16px 80px;
    border-bottom: 1px solid $main-black-9;
  }

  .ant-modal-body {
    padding: 40px;
  }

  .ant-modal-footer {
    padding-top: 0;
  }

  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: grid;
    gap: 12px;
  }

  .dragger-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    p.ant-upload-text {
      margin: 0 !important;
    }

    .button {
      height: 23px;
      width: 23px;
      svg {
        height: 23px;
        width: 23px;
        color: $main-black-6;
      }
    }
  }
  &-footer {
    display: inline-flex;
    gap: 20px;

    &.ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 0 !important;
    }
  }

  &-button {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    width: 88px;
  }
}
