@import "../../scss/variables/colors";

.sort-button {
  width: 39px !important;
  height: 39px !important;
  padding: 4px;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px;
  transition: all 0.5s;

  &.selected,
  &:hover {
    border-color: $main-red-1 !important;

    svg {
      fill: $main-red-1;
    }
  }

  svg {
    fill: #b5b5b5;
  }
}