@import "../../../common/ui/src/lib/scss/variables/colors";

.ni-product-settings {
  display: flex;
  flex-grow: 1;
  min-height: 100vh;

  &-content {
    flex: 2;
  }
}
