@import "../../scss/variables/colors";

.navigation-bar {
  z-index: 20;
  display: flex;
  flex: 1;
  min-height: var(--navigation-bar-height);
}

.navigation-bar-fixed-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.4rem 2.5rem;
  background-color: $neutral-white;
  border: 0.1rem solid $main-black-10;
  gap: 40px;
  width: 100vw;
  position: fixed;
}

.logo {
  height: 64px;
  width: 160px;
}

.frame-logo {
  height: 63px;
  width: 160px;
}

.frame-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0;
  margin-left: auto;
}

.financial-institution-title {
  font-weight: 420;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
}

.line-3-4-1 {
  transform: rotate(-90deg);
  height: 0;
  width: 24px;
  border-bottom: 2px solid $main-black-10;
  flex-grow: 0;
}

:global {
  .ant-avatar {
    background: white;
    border: none;
  }
}

.dropdown-search-test {
  width: 25%;
  :global {
    .ant-space-item {
      width: 100%;
      .ant-select {
        width: 120% !important;
        .ant-select-selector {
          height: 75% !important;
          .ant-select-selection-search-input {
            height: 35px;
          }
        }
      }
    }
  }
}

.search-page-option {
  color: $text-blue !important;
}
