@import "../../../../../common/ui/src/lib/scss/variables/colors";

.element-template-modal-table {
  .description {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  .table-row-grayed .ant-table-cell,
  .actions-cell-grayed button {
    cursor: not-allowed;
    pointer-events: none !important;
    opacity: 0.5;
  }

  .table-cell-icon {
    color: $main-black-6;
    padding: 8px;
    border-radius: 8px;
    background-color: $main-black-11;
  }

  .table-action-icon {
    height: 20px;
    color: $main-black-6;
  }

  .ant-dropdown-menu-title-content span {
    line-height: 20px;
  }

  .pin-disabled {
    color: $main-black-6;
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &-view {
    .ant-table-tbody {
      .table-view-element-template-name {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px;
        height: 30px;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .multiple-select-window {
    background: $neutral-white;

    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: $main-black-10;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
    border-radius: 12px 12px 0px 0px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 80px);
    height: 80px;
    z-index: 10;

    padding: 30px 40px;
    display: flex;

    .count-items {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $text-black;
    }

    .action-buttons {
      display: flex;
      margin-left: auto;
      gap: 40px;

      .button {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        color: $text-red;
        cursor: pointer;
        padding: 0;
        height: 20px;

        &:hover {
          color: #f26d81;
        }

        &-close {
          color: $main-black-6;
        }
      }
    }
  }
  .table-row-selected {
    background-color: #ffe5e5;

    &:hover {
      cursor: pointer;
      background-color: #f9d7d7;
      transition: 0.5s;
    }
  }

  :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
    cursor: pointer;
    background: transparent;
  }
}
