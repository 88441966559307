@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.rules-list {
  padding: 0;
  margin: 0;

  &-item {
    background-color: $main-black-13;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 16px;
    max-width: 1024px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      &-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    &__body {
      display: flex;
      gap: 16px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.pct-rules {
  &-ant-form-item {
    margin-bottom: 0;

    :global .ant-checkbox-wrapper {
      align-items: center;
    }
  }

  &-ant-checkbox-label {
    margin-top: 6px;
  }

  &-statement-button {
    background-color: $main-black-13;
    padding: 7px 15px 7px 12px;
    height: auto;
  }

  &-select-group {
    width: 50%;
    :global .ant-select {
      margin-right: 0;
      &:first-child {
        width: 30%;
        max-width: 110px;

        .ant-select-selector {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-right: 0;
        }
      }

      &:last-child {
        width: 70%;
        .ant-select-selector {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left: 0;
        }
      }
    }
    :global .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
  }

  &-select {
    width: 30%;
  }
}
