@import "../../scss/variables/colors";

:global {
  .ant-collapse.documentation-examples {
    border: none;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .ant-collapse-header {
      background-color: $neutral-white;
      color: $text-blue;
      font-weight: 500;
      font-size: 16px;
      padding: 0 0 16px 0;
    }

    .ant-collapse-content {
      border: none;

      .ant-collapse-content-box {
        padding: 0;
      }
    }

    > .ant-collapse-item {
      border: none;
    }
  }
}

.documentation-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}
