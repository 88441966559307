@import "../../scss/variables/colors";

.fullscreen {
  min-height: var(--content-height);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
