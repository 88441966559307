@import "../../../../../common/ui/src/lib/scss/variables/colors";

.qp-layout {
  width: 100%;
  overflow: hidden;

  .qp-form-search {
    margin-left: auto;

    .qp-search {
      width: 316px;
      height: 40px;
      
      svg {
        color: rgb(202, 202, 203);
      }
      
      ::placeholder {
        color: rgb(116, 116, 119);
      }
    }
  }

  .qp-content {
    width: 100%;
    padding: 0 40px 30px;

    .qp-button {
      min-width: 240px;
    }
  }

  
  :global {
    .ant-select {
      white-space: nowrap !important;
      height: 40px !important;
    }
  }
}