@import "../../../../../../../common/ui/src/lib/scss/variables/colors";

.currency-conversion-page {
  .fields-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.fee-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
}
