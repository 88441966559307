@import "../../../../../common/ui/src/lib/scss/variables/colors";

.form-container {
  width: 700px;
}

.save-button {
  padding-left: 80px;
  padding-right: 80px;
}
