@import "../../../../../common/ui/src/lib/scss/variables/colors";

.registration-page {
  padding: 40px;

  &.details {
    gap: 24px;
  }

  .reg-form {
    display: grid;
    gap: 24px;
    max-width: 500px;
    width: 100%;
  }

  .submit-btn {
    width: 206px;
    height: 40px;
  }

  .if-receive {
    display: flex;
    gap: 6px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
