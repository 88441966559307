@import "../../../../../common/ui/src/lib/scss/variables/colors";

.wizard-success {

  &-layout {
    padding: 30px 40px;
    max-width: 1024px !important;
    width: 100%;
  }
    
  &-icon {
    color: #53C4BB;
    :global {
      & svg {
        width: 70px;
        height: 70px;
      }
    }
  }

  &-title {
    margin-bottom: 0 !important;
  }

  &-details {
    :global {
      & h3 {
        font-size: 20px !important;
        font-weight: 500 !important;
        margin-bottom: 0 !important;
      }

      & span {
        color: $main-black-6;
      }
    }
  }

  &-actions {
    button {
      min-width: 208px;
    }
  }
}