@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.code-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.icons {
  color: $main-red-1;
}
