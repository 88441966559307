@import "../../scss/variables/colors";

.collapsed-table {
  :global {
    .ant-collapse-header {
      padding: 0 !important;
    }
    .ant-collapse-expand-icon,
    .ant-collapse-header {
      color: $text-blue !important;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
      padding-top: 24px !important;
    }
    .ant-table-cell:has(div#scenario-tabs-container) {
      padding: 0px;
    }
  }
}

.table-tabs-section {
  :global {
    .ant-tabs-nav {
      margin-bottom: 0 !important;
    }

    .ant-tabs-nav-list {
      padding-inline: 16px;
    }

    .ant-tabs-tab {
      background: none !important;
      padding-block: 24px !important;
      padding-inline: 0px !important;
      margin-right: 10px !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      border-radius: 0%;
      padding-block: 24px !important;
      padding-inline: 0px !important;
      margin-right: 10px !important;
      border-bottom: 2px solid $text-blue !important;
      .ant-tabs-tab-btn {
        font-weight: normal;
        color: $text-blue !important;
      }
    }

    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        font-weight: normal;
      }
    }
  }
}

.table-header-section {
  display: flex;
  flex: 1;
  justify-content: space-between;
  .row-main-description-table {
    text-align: start;
    flex: 0.5;
    align-items: center;
  }
  .table-header-section-rate {
    color: $text-dark-gray;
    flex: 0.5;
    justify-content: flex-end;
    align-items: center;
  }
}

.table-tabs-section-description {
  font-weight: normal;
  color: $text-blue;
  background-color: $text-white;
  text-align: start;
  padding: 16px;
}

.row-title-table {
  text-align: start;
}

.extended-Table-Container {
  padding-block: 0 !important;
  border: unset !important;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  :global {
    .ant-table-container {
      border: 0 !important;
    }

    .ant-table-cell {
      border-radius: 0 !important;
    }
  }
}