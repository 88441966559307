@import "../../../scss/variables/colors";

.card-display-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .product-name {
    margin: 0;
    padding-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}