@import "../../../../scss/variables/colors";

.links {
  display: flex;
  gap: 40px;
  line-height: 20px;

  :global {
    .admin-navigation-bar-link {
      color: black;

      &.active {
        color: $text-red;
      }
    }
  }
}
