@import "../../../../../common/ui/src/lib/scss/variables/colors";

.collapsible-panel {
  border-radius: 0;

  & > * {
    border-top: 1px solid $main-black-10;
  }

  :global {
    .anticon.ant-collapse-arrow {
      color: #0060A8;
    }

    .ant-collapse-header-text {
      font-weight: bold;
    }

    .ant-collapse-item > .ant-collapse-header {
      align-items: center;
    }
  }
}
