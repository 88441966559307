@import "../../../../../common/ui/src/lib/scss/variables/colors";

.tenant-fees-container {
  max-width: 700px;
  margin-bottom: 24px;
  :global {
    .section-title {
      color: $text-blue;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 24px;
    }
    .ant-select {
      max-width: 500px;
    }
  }

  .tenant-fees-form-item {
    align-items: center;
    display: flex;

    .tooltip-icon {
      margin-left: 8px;
    }

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-input-number {
        min-width: 500px;
      }

      .ant-form-item-control-input-content {
        button {
          margin-right: 18px;
        }
      }
    }
  }

  .tenant-fees-section {
    border: 1px solid $main-black-9;
    border-radius: 8px;

    .tenant-fees-select-item {
      max-width: 504px;
    }

    .tenant-fees-cb {
      background-color: $main-black-13;
      padding: 20px 16px;
      border-bottom: 1px solid $main-black-9;
      border-radius: 8px 8px 0 0;

      .tenant-fees-text-cb {
        color: $text-black;
        font-weight: 500;
        font-size: 20px;
      }
    }

    .tenant-fees-form-item {
      align-items: center;
      display: flex;

      :global {
        .ant-form-item-control-input-content {
          button {
            margin-right: 18px;
          }
        }
      }
    }
  }

  .item-margin {
    margin: 0px 16px 0px;
    padding: 0px;
  }
  .item-margin-top {
    margin: 10px 16px 0px;
  }
  .item-margin-bot {
    margin: 0px 16px 16px;
  }
}

.section-description {
  font-size: 14px;
  line-height: 22px;
}

.tenant-fees-submit-button {
  min-width: 250px;
}

.text-uppercase {
  text-transform: uppercase;
}
