@import "../../scss/variables/colors";

.tab-content-grey-section-wrapper {
  padding: 16px;
  border-radius: 16px;
  background: var(--neutral-white);
}

.tab-content--description {
  font-family: Gotham;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.tab-row-label {
  color: var(--text-black);
  font-family: Gotham;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.tab-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
