@import "../../scss/variables/colors";

.envelop {
  margin: 0 0 20px;
  border-radius: 20px;
  padding-top: 24px;
  padding-bottom: 15px;
  background-color: $light-gray;
  overflow: auto;

  .switch-item {
    &.listItem {
      width: 100%;
    }
  }

  .envelop-switches {
    column-count: 2;
    column-rule-style: dashed;
    column-rule-width: 1px;
    column-rule-color: $main-black-9;
  }

  @media (max-width: 1400px) {
    .envelop-switches {
      column-count: 1;
      column-rule-style: dashed;
      column-rule-width: 1px;
      column-rule-color: $main-black-9;
    }
  }

  .text-blue-h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: $text-blue;
    text-transform: uppercase;
    margin-left: 20px;
    margin-bottom: 24px;
  }
}
