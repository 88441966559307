@import "../../../../../common/ui/src/lib/scss/variables/colors";

.event-group {
  cursor: move;
  opacity: 1;
  border-radius: 8px;

  &.notDND {
    cursor: default !important;

    .ant-collapse-content-box {
      margin: 0;
    }

    .ant-collapse-header {
      height: 74px;
      padding: 16px 24px !important;
    }
  }

  &.opacity {
    opacity: 0;
  }

  :global {
    .ant-collapse {
      border-radius: 8px;

      .ant-collapse-item:last-child {
        border-radius: 8px;

        .ant-collapse-header {
          border-radius: 8px;
        }
      }
    }
  }

  &-collapse {
    background: $main-black-11;

    :global {
      .ant-collapse-header-text {
        width: 100%;
      }

      .ant-collapse-header {
        align-items: center !important;
        display: flex !important;
        padding: 16px !important;
      }

      .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
        flex: auto !important;
      }
    }
  }
}
