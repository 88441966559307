@import "../../../../../common/ui/src/lib/scss/variables/colors";

.tenant {
  &-div {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: white;

    :global {
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 16px 0;
      }
    }
  }

  &-collapse {
    border: 0 !important;
    border-bottom: 1px solid $main-black-9 !important;
    border-radius: 0 !important;
    background-color: white !important;

    :global {
      .ant-collapse-item {
        border: 0;

        &:last-child {
          .ant-collapse-header {
            align-items: center;
            border-radius: unset;
          }
        }

        .ant-collapse-header {
          padding: 10px 0 !important;
        }
      }
    }

    &-extra {
      background: $button-gray-blue;
      border-radius: 8px;
      flex-wrap: wrap;
      gap: 10px;
      font-weight: 325;
      font-size: 14px;
      line-height: 20px;
      color: $text-white;
      display: flex;
      justify-content: flex-end;
      min-width: 140px;
      align-items: center;
      padding: 8px 16px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      border: none;
      cursor: pointer;

      &:hover {
        background-color: $button-gray-blue-hover;
      }

      &:active {
        background-color: $button-gray-blue-active;
      }
    }
    &-create-Product {
      background: $button-red-active;

      &:hover {
        background-color: $button-red-disabled;
      }

      &:active {
        background-color: $danger-color;
      }
    }
  }

  &-products {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  &-product-card {
    margin: 0;
  }

  &-header {
    margin-left: 12px;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
}

.tenant-name {
  font-weight: 420;
  font-size: 24px;
  line-height: 32px;
  color: $main-black-2;
  margin-left: 8px;
  height: 32px;
}

.tenant-logo {
  max-width: 32px;
  max-height: 32px;
}
