@import "../../../../../common/ui/src/lib/scss/variables/colors";

.product-details-form {
  max-width: 60%;
}

.product-details-switch {
  display: flex;
  align-items: baseline;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.details-wrapper {
  background-color: white;
  padding: 30px 40px;
  min-height: var(--content-height);
}

.product-details-icon {
  color: rgba(0, 0, 0, 0.45);
}

.fees-product-input-width {
  max-width: var(--fees-input-max-width);
}

div:has(> .tab-title-tooltip) {
  margin-right: 0px !important;
}

.tab-title-tooltip {
  margin-right: 0px !important;
}
