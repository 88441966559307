@import "../../scss/variables/colors";

.category-row {
  background-color: #fafafb;

  :global {
    td.ant-table-cell:nth-child(1) {
      span.ant-table-cell-content {
        font-weight: 500 !important;
        color: $main-blue-2 !important;
      }
    }
  }

  td {
    cursor: default !important;
  }
}

.smc-table {

  :global {
    td.ant-table-cell:nth-child(1) {
      font-weight: 500 !important;
      background-color: #fafafb !important;
    }
    
    td.ant-table-cell-row-hover:not([title=""]) {
      background: $main-red-11 !important;
    }

    .ant-form-item-control-input {
      min-height: unset !important;
    }
  }
}

.action-button {
  height: 24px !important;
  width: 24px !important;
  :global {
    .ant-btn-icon {
      > div {
       gap: unset;
       margin: unset 
      }
    }
  }
}

.name-style {
  font-size: 11px;
  padding: 2px 8px 2px 8px;
  line-height: 20px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  
  &.filled {
    color: $text-blue;
    border: 2px solid $text-blue;
    border-radius: 18px;
  }

  &.unfilled {
    color: $text-dark-gray;
    border: 2px solid $text-dark-gray;
    border-radius: 18px;
  }
}

.button {
  width: 208px;
}

.select {
  flex: 0 !important;
  width: 350px !important;
  height: 40px !important;
  margin: 0 !important;
}


.table-edit-input {
  border-radius: 0 !important;
  height: 38px !important;
  padding: 7px !important;
}

.table-edit-number {
  border-radius: 0 !important;
  height: 38px !important;
  :global {
    .ant-input-number-input-wrap, .ant-input-number-input {
      height: 100% !important;
    }
    .ant-input-number-input {
      padding: 3px 7px !important;
    }
  }
}

.table-edit-select {
  :global {
    .ant-select-selector {
      border-radius: 0 !important;
      height: 38px !important;
    }
  }
}

.is-editing {
  padding: 0 !important;
}

.is-disabled {
  background-color: #fafafb !important;
}