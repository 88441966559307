@import "../../../../../common/ui/src/lib/scss/variables/colors";

.general-cr-parameters-container {
  max-width: 700px;

  .general-cr-parameters-input-item {
    max-width: 504px !important;

    :global {
      .ant-input-number {
        max-width: unset;
      }
    }
  }

  .general-cr-parameters-form-item {
    align-items: center;
    display: flex;

    .tooltip-icon {
      margin-left: 8px;
    }

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-control-input-content {
        button {
          margin-right: 18px;
        }
      }
    }
  }
}

.general-cr-parameters-submit-button {
  min-width: 250px;
}

.general-cr-parameters-info {
  background-color: $main-black-15;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 40px;
  }

  .general-cr-parameters-info-link-text {
    margin-right: 8px;
  }
}

.text-uppercase {
  text-transform: uppercase;
}
