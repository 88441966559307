@import "../../scss/variables/colors";

.filter-bar {
  .inputs {
    width: 100%;

    :global {
      .ant-form-item {
        width: 100%;
      }

      .ant-input-lg {
        padding: 11px;
      }
    }
  }
}
