@import "../../scss/variables/colors";

.heading-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 43px 40px 6px;
  gap: 6px;
  overflow-x: hidden;
  width: 100%;

  :global {
    h2.ant-typography {
      margin-bottom: 24px;
    }
  }
}
