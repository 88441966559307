@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.tenant-details-form {
  max-width: 800px;

  &-switch {
    display: flex;
    align-items: baseline;
  }
}

.details-wrapper {
  background-color: white;
  padding: 30px 40px;
  min-height: 100vh;
}

.currency-select {
  max-width: 437px;
}
