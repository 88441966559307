@import "../../../../../common/ui/src/lib/scss/variables/colors";

.sms-templates {
  background-color: white;
  padding: 40px 0;
  min-height: var(--content-height);

  :global {
    .anticon {
      font-size: 16px;
      margin-left: 8px;
    }
  }

  .page-title {
    padding: 0 30px;
  }
}
.block-codes-editing-submit-button {
  min-width: 100px;
}

.search-input-container {
  display: flex;
  margin-inline: 25px;
  .fc-filter-search {
    width: 300px;
    height: 40px;

    svg {
      color: rgb(202, 202, 203);
    }

    ::placeholder {
      color: rgb(116, 116, 119);
    }
  }
}
