@import "../../scss/variables/colors";

.fees-row-inputs-wrapper {
  :last-child {
    margin-bottom: 0;
  }

  .fees-row-input {
    padding-right: 24px;
  }

  div:has(.first-row-cell) {
    padding: 8px;
  }
}

.heading-label {
  width: 100%;

  > div {
    padding-right: 24px;
  }
}

.ni-label {
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $text-black;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
