@import "../../../../../common/ui/src/lib/scss/variables/colors";

:global {
  .element-template-layout {
    display: flex;
    flex-direction: column;
    width: 100%;

    .extra {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-right: 0;
      margin-left: auto;

      .ant-input-affix-wrapper {
        height: 40px;
      }

      .ant-form-item {
        height: 40px;
        margin-bottom: 24px;
      }
    }

    .filters-bar {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      padding: 24px 40px 20px;
      column-gap: 24px;

      .ant-form-item {
        min-width: 150px;
        flex: 1 1 0;
      }

      .type-filter {
        height: 40px;
        margin-bottom: 24px;
        margin-top: 0;
        display: flex;

        label {
          height: 40px;

          span {
            height: 40px;
            line-height: 40px;
          }
        }
      }

      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background-color: $main-black-13;
        border-color: #d3d4d4;
        color: $main-black-2;
        font-weight: 500;

        &::before {
          background-color: #d3d4d4;
        }
      }
    }

    .new-element-template-button {
      height: 40px;
      margin-right: 24px;
      margin-bottom: 24px;
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  .element-template-setting-layout-wrapper {
    display: flex;
    flex-grow: 1;

    .element-template-sidenav {
      width: 300px;
      border-right: 1px solid $main-black-10;

      .element-template-select {
        margin: 24px 10px 16px 10px;
        width: auto;

        .ant-select-selector {
          border: none;
          box-shadow: none !important;
          height: 100%;
        }
      }
    }

    .page-content {
      flex: 2;
    }

    .element-template-sidenav .select-wrapper {
      align-items: center;
      display: flex;
      height: 100%;

      .select-label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $main-black-3;
        margin-bottom: 0;
        white-space: break-spaces;

        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
