@import "../../scss/variables/colors";

.layout {
  padding: 30px 40px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.wrapper {
  margin-top: 40px;

  &.full {
    max-width: 100% !important;
  }

  &.lg {
    max-width: 1152px !important;
  }

  &.md {
    max-width: 1024px !important;
  }

  &.sm {
    max-width: 668px !important;
  }

  .details-icon {
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.45);
  }

  .list {
    width: 100%;
  }
}

.decline-fee-page {
  .wrapper {
    margin-top: 12px;
  }
}

.balance-inquiry-page {
  .wrapper {
    margin-top: 40px;
  }

  .pct-save-button {
    margin-bottom: 20px;
  }

  :global {
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }

    .ant-form-vertical .ant-form-item-label .ant-form-item-tooltip {
      margin: 0;
    }

    .ant-input-number,
    .ant-input-group {
      width: 500px;
      max-width: 500px;
      text-align: right;
    }

    .ant-input-number input {
      text-align: right;
      padding-right: 12px;
    }
  }

  .default-inquiry-fee {
    display: flex;
    flex-direction: column;
    gap: 36px;

    :global {
      .ant-space-vertical {
        gap: 20px !important;
      }
    }
  }

  .customized-inquiry-fees {
    display: flex;
    flex-direction: column;
    gap: 24px;

    :global {
      .ant-space-vertical {
        gap: 20px !important;
      }
    }

    .switch-label {
      color: #313136;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    .switch-label-wrapper {
      display: flex;
      align-items: center;
    }
  }
}

.subtitle {
  margin-top: 16px;
  max-width: 1024px;
  font-size: 14px;
  line-height: 1.6;

  &.full {
    max-width: 100% !important;
  }

  &.md {
    max-width: 1024px !important;
  }

  &.sm {
    max-width: 668px !important;
  }
}
