@import "../../../../common/ui/src/lib/scss/variables/colors";

.collapse-header {
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: center;

    .input {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .title {
      display: grid;
      gap: 4px;
    }

    .name {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $text-black;
      display: flex;
      gap: 8px;

      align-items: center;
      justify-content: center;
    }

    .order {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      height: 40px;
      width: 40px;
      cursor: no-drop;
      background: $main-black-11;
      box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.15);
    }

    .description {
      font-weight: 325;
      font-size: 12px;
      line-height: 14px;
      color: $text-dark-gray;
    }

    .arrow {
      transform: rotate(0deg);
      transition: transform 0.25s linear;
    }

    .arrow.open {
      transform: rotate(180deg);
      transition: transform 0.25s linear;
    }

    &-extra {
      display: flex;
      gap: 16px;
      align-items: center;

      .button {
        cursor: pointer;
        color: $button-red-active;

        &:hover,
        &:focus {
          color: #f26d81;
        }
      }
    }
  }
