@import "../../../common/ui/src/lib/scss/variables/colors";

.DashboardLayout {
  display: flex;
  flex-direction: column;

  .extra {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto;

    .new-product-button {
      height: 40px;
      margin-right: 24px;
      padding-right: 24px;
      padding-left: 24px;
    }

    :global(.ant-input-affix-wrapper) {
      height: 40px;
    }

    .dashboard-view-switch {
      margin-left: 20px;

      .view-switch {
        width: 40px;
        height: 40px;
        margin: 0 8px;
        padding: 4px;
        border: 1px solid $button-border-gray;
        border-radius: 4px;
      }
    }
  }

  .filters-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 24px 40px 20px;
    column-gap: 24px;

    :global(.ant-form-item) {
      min-width: 150px;
      flex: 1 1 0;
    }
  }

  .empty-tenants {
    display: flex;
    flex-direction: column;
    padding: 40px;
    place-items: center;
    box-sizing: border-box;

    * {
      max-width: 700px;
    }

    .empty-tenants-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      padding-top: 40px;
      padding-bottom: 16px;
      text-align: center;
    }

    .empty-tenants-description {
      color: $text-dark-gray;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      padding-bottom: 40px;
    }

    :global {
      a > .ant-btn-primary.ant-btn-lg {
        padding: 6px 65px;
      }
    }
  }
}
