@import "../../../../../common/ui/src/lib/scss/variables/colors";

.page-heading-wrapper {
  :global {
    .heading-bar {
      align-items: flex-start;
    }
  }

  display: flex;
  flex-direction: column;
  width: 100%;

  .extra-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto;
    gap: 24px;
    margin-bottom: 24px;

    .top-section {
      display: flex;
      gap: 24px;
      flex-direction: row;

      .new-button {
        height: 40px;
        padding-right: 40px;
        padding-left: 40px;
      }

      :global .ant-form-item {
        margin-bottom: 0;
        .ant-input-affix-wrapper {
          height: 40px;
        }
      }
    }

    .bottom-section {
      display: flex;
      justify-content: flex-end;
      :global {
        .ant-btn-text {
          color: $text-red;
          display: flex;
          .anticon {
            font-size: 24px;
          }
        }
      }
    }
  }
}

.content {
  margin: 40px;
  .panel-header {
    align-items: center;
    display: flex;
    background-color: pink;
  }

  :global .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
    padding: 12px 40px;
  }

  :global .ant-collapse-borderless {
    background: $text-white;
  }

  :global .ant-collapse-item {
    &:first-child {
      border-top: 1px solid $main-black-10;
    }
  }
}
