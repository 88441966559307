@import "../../../../../common/ui/src/lib/scss/variables/colors";

.page {
  cursor: move;
  opacity: 1;
  border-radius: 8px;
  border: 1px solid #cdcdce;
  background-color: #fbfbfb;

  &.notDND {
    cursor: default !important;

    :global {
      .ant-collapse-content-box {
        padding: 16px;
        margin: 0;
      }

      .ant-collapse-header {
        height: 74px;
        padding: 16px 24px !important;
        background-color: #fbfbfb;
      }
    }
  }

  &.opacity {
    opacity: 0;
  }

  &-collapse {
    :global {
      .ant-collapse-header-text {
        width: 100%;
      }

      .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
        flex: auto !important;
      }
    }

    &-header {
      width: 100%;
      display: flex;
      gap: 12px;
      align-items: center;

      :global {
        .input {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .description {
          font-weight: 325;
          font-size: 12px;
          line-height: 14px;
          color: $text-dark-gray;
        }

        .arrow {
          transform: rotate(0deg);
          transition: transform 0.25s linear;
        }

        .arrow.open {
          transform: rotate(180deg);
          transition: transform 0.25s linear;
        }
      }

      .title {
        display: grid;
        gap: 4px;
      }

      .name {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #313136;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
      }

      .order {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 40px;
        width: 40px;
        cursor: no-drop;
        background: #fbfbfb;
        box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.15);
      }

      &-extra {
        display: flex;
        gap: 16px;
        align-items: center;
        height: 40px;

        .button {
          cursor: pointer;
          color: $button-red-active;

          &:hover,
          &:focus {
            color: #f26d81;
          }
        }
      }
    }
  }
}
