@import "../../../../../common/ui/src/lib/scss/variables/colors";

.tenant-table-view {
  padding: 40px;

  :global {
    .table-row-grayed {
      .ant-table-cell {
        opacity: 0.5;
      }
    }
  }
}

.table-draft-warning-sign {
  color: $warning-color;
}

.tenant-table {
  :global {
    tr:nth-child(2n + 1) td {
      background-color: $main-black-15;
    }
  }
}


.table-view-tenant-name {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px;
  height: 30px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
