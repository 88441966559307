@import "../../../../../common/ui/src/lib/scss/variables/colors";

.fi-details {
  background-color: $neutral-white;
  padding: 30px 40px;
  min-height: var(--content-height);

  &-switch-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }

  &-form {
    margin-top: 40px;
    max-width: 500px;

    &-switch {
      display: flex;
      align-items: baseline;

      :global {
        .ant-form-item {
          margin-right: 4px;
        }
      }
    }
  }
}

:global {
  .ant-select {
    border-radius: 8px !important;
  }
}

.phone-number-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #cdcdce;
  border-radius: 8px;
  transition: all 0.3s;
}
