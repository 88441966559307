@import "../../scss/variables/colors";

.collapsed-links {
  padding: 0;
  margin-top: 24px;

  :global {
    .ant-collapse-header-text {
      font-weight: 500;
    }
    .ant-collapse-content-box > a {
      color: $text-blue;
      display: block;
      width: max-content;
    }
  }
}
