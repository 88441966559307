@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.limits-input {
  width: 100%;

  input {
    width: 100%;
  }

  :global {
    .ant-form-item,
    .ant-form-input,
    .ant-form-item-control-input {
      width: 100%;
    }
  }
}
