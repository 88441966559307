@import "../../../../../common/ui/src/lib/scss/variables/colors";

.pct-editing-box {
  background-color: $main-black-13;
  padding: 16px;
  border-radius: 16px;

  &-title {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;

    &__switch {
      display: flex;
      align-items: center;
    }
  }

  &-input {
    margin-top: 20px;
    max-width: 500px;

    input {
      text-align: right !important;
      padding: 8px 12px;
    }
  }
}
