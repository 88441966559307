@import "../../../../../common/ui/src/lib/scss/variables/colors";

.lty-modal-group {
  display: flex;
  overflow-y: auto;
  padding-right: 10px;
  max-height: 330px;
  width: 100%;
  gap: 16px;
}

.lty-modal-select-all { color: $main-red-1; }