@import "../../../../../common/ui/src/lib/scss/variables/colors";

$user-height-padding: 30px;
$user-left-padding: 56px;
$block-codes-list-padding: 15px;
$user-top-padding: 16px;

.user-settings-wrapper {
  padding-left: $user-left-padding;
  padding-bottom: $user-height-padding;
  padding-top: $user-top-padding;
  overflow-y: auto;
  width: 40%;
  overflow-x: hidden;

  .reset {
    &-password {
      display: flex;
      gap: 24px;
      height: 40px;
    }

    &-btn {
      width: 208px;
      height: 100%;
    }

    &-description {
      color: $text-dark-gray;
    }
  }

  .phone-number-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #cdcdce;
    border-radius: 8px;
    transition: all 0.3s;
  }
}

.form-item, .reset-password {
  align-items: center;
  display: flex;
  margin-bottom: 24px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-control-input-content {
      button {
        margin-right: 18px;
      }
    }
  }
}
