@import "../../../../../common/ui/src/lib/scss/variables/colors";

$page-height-padding: 30px;
$page-left-padding: 56px;
$block-codes-list-padding: 15px;

.page-settings-wrapper {
  padding-left: $page-left-padding;
  padding-bottom: $page-height-padding;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;

  :global {
    .ant-collapse {
      border-radius: 8px;

      .ant-collapse-item:last-child {
        border-radius: 8px;

        .ant-collapse-header {
          border-radius: 8px;
        }
      }
    }

    .ant-collapse,
    .ant-collapse-header,
    .ant-collapse-item,
    .ant-collapse-content {
      font-size: 16px;
    }

    .ant-collapse-expand-icon {
      color: $text-blue !important;
    }

    .ant-collapse-content-box {
      margin: 24px 0 0 0;
      border: none;
      display: grid;
      gap: 24px;

      .form-item {
        margin: 0;

        &.phase {
          width: 632px;
        }
      }
    }

    .ant-collapse-extra {
      margin-left: 5px !important;
      display: flex;
      align-items: center;
      height: 40px;

      svg {
        height: 12px;
        width: 12px;
        margin-bottom: 1px;
        color: $icon-background-gray;
      }
    }
  }

  .page-select-item {
    max-width: 600px;
  }

  .tab-wrapper {
    margin-top: 20px;

    &-switch {
      display: flex;
      align-items: baseline;
    }

    .inner-item {
      margin-top: 20px;
    }
  }

  .page-collapse-text {
    font-size: 16px;
    font-weight: 500;
  }

  .block-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $text-blue;
    text-transform: uppercase;
  }
  .ant-collapse,
  .ant-collapse-header,
  .ant-collapse-item,
  .ant-collapse-content {
    font-size: 16px;
  }

  .ant-collapse-expand-icon {
    color: $text-blue !important;
  }

  .ant-collapse-content-box {
    margin: 24px 0 0 10px;
    border: none;
    display: grid;
    gap: 24px;

    .form-item {
      margin: 0;

      &.phase {
        width: 632px;
      }
    }
  }

  .ant-collapse-extra {
    margin-left: 5px !important;
    display: flex;
    align-items: center;
    height: 40px;

    svg {
      height: 12px;
      width: 12px;
      margin-bottom: 1px;
      color: $icon-background-gray;
    }
  }

  .elements-header-block {
    display: flex;
    margin-bottom: 24px;

    .block-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $text-blue;
      text-transform: uppercase;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .element-details-form-buttons {
      margin-left: auto;
      margin-right: 16px;
    }
  }

  .element-list {
    display: grid;
    gap: 24px;
    padding: 0 16px;
  }

  .page-save-button {
    width: 204px;
    margin-left: 16px;
  }
  .empty-description {
    padding-left: 16px;
  }

  .settings-group {
    display: flex;
    gap: 24px;
  }

  .settings-group > div {
    flex: 1 1 auto;
    margin: 5px;
  }

  .page-settings-form-switch {
    display: flex;

    .text {
      color: $text-black;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
    }
  }

  .page-settings-panel-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: $text-black;

    span {
      gap: 8px;
      display: flex;
      align-items: center;
    }
  }

  .page-content-wrapper {
    width: 50%;
  }
}
