@import "../../../common/ui/src/lib/scss/variables/colors";

.container {
  min-height: var(--content-height);
  display: flex;
  flex-direction: row;

  .image {
    width: 50%;
    background-image: url(./components//helpers/background.svg);

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    width: 50%;
  }
}
