@import "../../scss/variables/colors";

.ni-truncated-label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ni-form-label-container {
  width: 100%;
  overflow: hidden;
  
  :global {
    .anticon-file-excel {
      svg {
        margin-left: 0 !important;
      }
    }

    .ant-tooltip-inner {
      color: aqua !important;
    }

    .ant-space-item {
      &:nth-child(1) {
        width: 100%;
        overflow: hidden;
      }

      &:not(:nth-child(1)) {
        display: flex;
        justify-content: center;
      }
    }
  }

  .form-item-code-icon {
    color: $icon-background-gray;
  }
}
