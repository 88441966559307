@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.setting {
  &-title {
    color: $text-blue !important;
    font-size: 20px !important;
    line-height: 28px !important;

    margin-bottom: 16px !important;

    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  gap: 8px;
  border-color: #E4E4E4;
  padding: 16px;
  height: 152px;

  :global {
    .ant-card-head {
      font-size: 14px;
      min-height: unset;
      border-bottom: 0;
      padding: 0;
    }

    .ant-card-body {
      padding: 0;
      overflow: hidden;
    }
  }
}
.text-overflow {
  text-overflow: ellipsis;
}

.tooltip {
  max-width: none;
  :global {
    .ant-tooltip-inner {
      color: var(--text-black) !important;
    }
  }
}
