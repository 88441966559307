@import "../../scss/variables/colors";

.sms-variable-preview {
  :global {
    .ant-input {
      color: $background-light-gray;
      caret-color: transparent;
    }
  }
}
