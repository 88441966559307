@import "../../scss/variables/colors";

.switch-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 20px;
  align-items: center;
  margin-left: auto;
  width: 100%;

  .warning-sign {
    svg {
      color: $icon-background-gray;
    }
  }

  &.no-padding {
    padding-top: 0;

    .switch-content {
      width: 100%;
      display: flex;
      max-width: calc(100% - 60px);

      :global {
        .ant-space-item:nth-of-type(2) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      :global(.anticon) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .edit-button {
    text-align: right;
    padding-left: 20px;
    color: $main-red-1;
    font-size: 12px;
    line-height: 28px;

    :global {
      .ant-btn {
        border: 0;
        padding: 0 !important;
        max-height: 24px;
      }
    }
  }
}
