@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.registration-header {
  width: 612px;
  margin-bottom: 40px;

  .title {
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
  }

  .red {
    color: $text-red;
  }

  .blue {
    color: $text-blue;
  }

  .description {
    margin-top: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
  }
}
