@import "../../../scss/variables/colors";

:global(.currencies-table) {
  width: 820px;

  &.full-width {
    width: 100%;
  }

  .first-raw-disabled {
    background-color: $main-black-15;
  }

  .ant-table-cell {
    ::before {
      display: none;
    }
  }

  :global {
    .ant-checkbox-inner {
      height: 20px;
      width: 20px;
      left: calc(50% - 20px / 2);
      top: calc(50% - 20px / 2);
    }

    .ant-checkbox-input {
      margin-left: 2px;
    }

    .ant-checkbox-inner::after {
      margin-left: 1px;
    }
  }

  .base-currency {
    width: 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 12px;
    grid-gap: 10px;
    gap: 10px;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 8px;
    font-size: small;
    margin-bottom: 0;
  }

  .add-button {
    width: auto;
    margin-top: 12px;
    padding: 0 12px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    color: $text-red;

    display: flex;

    &:disabled {
      color: $text-light-gray;
    }
  }
}
