@import "../../scss/variables/colors";

.file-card-list-wrapper-page {
  background-color: white;
  padding: 30px 40px;
  min-height: var(--content-height);

  .file-card-list-wrapper-header {
    :global {
      .description {
        margin-bottom: 40px;

        .anticon-question-circle {
          margin-left: 8px;
        }
      }
    }
  }

  :global {
    .file-card-list-wrapper-body .file-card-list-wrapper-content-block .file-card-list-wrapper-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;

      .file-card-container {
        flex-basis: 48%;
      }
    }
  }
}
