@import "../../../scss/variables/colors";

.progress-bar {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 40px;
  min-width: 20%;

  .rectangle {
    display: inline-block;
    width: 24px;
    height: 6px;
    background: $button-gray;
    border-radius: 2px;
    transform: rotate(-180deg);
    margin-right: 4px;
  }

  .rectangle-done {
    display: inline-block;
    width: 24px;
    height: 6px;
    background: $button-done;
    border-radius: 2px;
    transform: rotate(-180deg);
    margin-right: 4px;
  }

  .rectangle-blue {
    display: inline-block;
    width: 24px;
    height: 6px;
    border-radius: 2px;
    transform: rotate(-180deg);
    margin-right: 4px;
    background: $text-blue;
  }

  .rectangle-active {
    display: inline-block;
    width: 24px;
    height: 6px;
    background: $button-red-active;
    border-radius: 2px;
    transform: rotate(-180deg);
    margin-right: 4px;
  }
}
