@import "../../../../common/ui/src/lib/scss/variables/colors";

.container {
  width: 90%;

  .extra-container {
    margin-right: 0;
    margin-left: auto;

    .top-section {
      display: flex;

      .new-button {
        height: 40px;
        margin-right: 24px;
        padding-right: 40px;
        padding-left: 40px;
      }

      :global .ant-form-item {
        margin-bottom: 0;

        .ant-input-affix-wrapper {
          height: 40px;
        }
      }
    }
  }

  .features-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 24px;
    
    margin-left: 40px;
    margin-right: 40px;
    & > * {
      width: 100%;
      max-width: calc(50% - 8px);
      min-width: 400px;
      box-sizing: border-box;
    }
  }
}
