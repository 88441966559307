$text-black: #313136;
$text-blue: #0060a8;
$text-dark-gray: #8a8a8d;
$text-light-gray: #b7b7b9;
$text-red: #e64360;
$text-white: #ffffff;

$light-gray: #f9f9f9;
$light-gray-1: #f0f0f0;

$warning-color: #ffa500;
$danger-color: #ff0000;

$background-light-blue: #f2f5f7;
$background-light-gray: #fafafa;

$button-red-active: #e64360;
$button-red-disabled: #ee8c9e;

$button-done: #53c4bb;
$button-gray: #ebebeb;
$button-gray-blue: #6494b4;
$button-gray-blue-hover: #8bb6d3;
$button-gray-blue-active: #3a698f;

$icon-background-gray: #b6b7b8;

$button-border-gray: #e2e2e3;
$border-header: #eaeaea;

$logo-blue: #0060a8;
$logo-red: #e64360;

$additional-gray-9: #e2e2e3;

$neutral-white: #ffffff;

$alert-blue-2: #91d5ff;
$alert-blue-3: #e6f7ff;
$alert-gray: #6d6e71;

$main-blue-1: #0060a8;
$main-blue-2: #196fb0;
$main-blue-3: #327fb8;
$main-blue-4: #4b8ec1;
$main-blue-5: #649ec9;
$main-blue-6: #7dadd1;
$main-blue-7: #96bcd9;
$main-blue-8: #afcce1;
$main-blue-9: #c8dbea;
$main-blue-10: #e5eaef;
$main-blue-11: #f2f5f7;
$main-blue-12: #f7fafc;

$main-red-1: #e64360;
$main-red-2: #e8556f;
$main-red-3: #ea687f;
$main-red-4: #ec7a8e;
$main-red-5: #ee8c9e;
$main-red-6: #f09fad;
$main-red-7: #f2b1bc;
$main-red-8: #f4c3cc;
$main-red-9: #f6d5db;
$main-red-10: #f8e8eb;
$main-red-11: #fcf4f5;
$main-red-12: #fcf7f8;

$main-black-0: #000000;
$main-black-1: #1b1b20;
$main-black-2: #313136;
$main-black-3: #48484c;
$main-black-4: #5e5e61;
$main-black-5: #747477;
$main-black-6: #8a8a8d;
$main-black-7: #a1a1a3;
$main-black-8: #b7b7b9;
$main-black-9: #cdcdce;
$main-black-10: #e4e4e4;
$main-black-11: #efefef;
$main-black-13: #f6f6f6;
$main-black-14: #f9f9f9;
$main-black-15: #fbfbfb;

$main-black-1-5: #0000003f;
