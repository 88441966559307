@import "../../../../../common/ui/src/lib/scss/variables/colors";

.buttons {
  display: flex;
  gap: 24px;
}

.variables-dictionary-modal {
  margin-top: 16px;
  margin-left: 80px;
  max-width: 660px;
}
