
@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  src: url(fonts/Gotham-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  src: url(fonts/Gotham-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  src: url(fonts/Gotham-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 600;
  src: url(fonts/Gotham-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  src: url(fonts/Gotham-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 800;
  src: url(fonts/Gotham-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 900;
  src: url(fonts/Gotham-Ultra.ttf) format('truetype');
}
