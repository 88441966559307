@import "../../../../../common/ui/src/lib/scss/variables/colors";

.fi-setting-layout-wrapper {
  display: flex;
  flex-grow: 1;

  .fi-sidenav {
    width: 300px;
    border-right: 1px solid $main-black-10;
    padding-top: 48px;

    .sidenav-wrapper {
      padding-top: 48px;
    }
  }

  .page-content {
    flex: 2;
  }
}
