@import "../../scss/variables/colors";

.file-card-container {
  align-items: flex-start;
  border: 1px solid $button-border-gray;
  border-radius: 8px;
  column-gap: 16px;
  display: flex;
  padding: 16px;
  flex-basis: 48%;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .title {
      color: $main-black-2;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      margin-bottom: 8px;
      text-transform: capitalize;
    }

    .description {
      color: $main-black-2;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
    }

    .details {
      color: rgba(0, 0, 0, 0.5);
      column-gap: 16px;
      display: flex;

      :global {
        .ant-btn-text {
          color: $main-red-1;
          font-size: 12px;
          line-height: 16px;
          padding: 0;
        }
      }
    }
  }

  .warning-sign {
    color: $danger-color;
    margin-left: auto;
  }

  .card-disabled {
    opacity: 0.5;
  }
}
