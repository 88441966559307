@import "../../../../../common/ui/src/lib/scss/variables/colors";

.workflow-layout {
  display: flex;
  flex-direction: column;
  width: 100%;

  .extra {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto;

    :global {
      .ant-input-affix-wrapper {
        height: 40px;
      }
    }
  }

  .filters-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 24px 40px 20px;
    column-gap: 24px;

    :global {
      .ant-form-item {
        min-width: 150px;
        flex: 1 1 0;
      }
    }
  }

  .new-workflow-button {
    height: 40px;
    margin-right: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }
}
