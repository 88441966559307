thead {
  font-weight: 600;
}

b,
strong {
  font-weight: 600;
}

textarea::placeholder {
  font-size: 14px;
}

h1 {
  color: $text-blue !important;
}

.alert-div {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 12px;
  gap: 10px;
  background: $alert-blue-3;
  border: 1px solid $alert-blue-2;
  border-radius: 8px;
  font-size: small;
  margin-bottom: 0;
  &.center {
    align-items: center;
    justify-content: center;
  }
  &.small {
    height: 32px;
  }
}

.text-blue-h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: $text-blue;
  text-transform: uppercase;
}

.text-black-h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: $main-black-1;
}

.limits-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  border: 1px solid $main-black-10;
  border-radius: 7px;
  margin-bottom: 16px;
  min-width: 700px;

  &.tiny {
    width: 100%;
    max-width: 274px;
    min-width: auto;
  }

  &.small {
    width: 100%;
    max-width: 668px;
    min-width: auto;
  }

  &.md {
    width: 100%;
    max-width: 1024px;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    width: 100%;
    background: $main-black-13;
    border-bottom: 1px solid $main-black-10;
    border-radius: 7px 7px 0 0;
    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 24px;
    color: $text-black;

    .header-switch {
      margin-bottom: 0 !important;
    }

    span.anticon.anticon-question-circle {
      font-size: 14px;
    }
  }

  &-body {
    width: 100%;
    padding: 12px 12px 6px;

    .ant-col {
      font-style: normal;
      line-height: 16px;
      color: $text-black;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 5px !important;
      font-size: 14px;

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-input-number {
        max-width: 100%;
      }

      .ant-input-number-group-wrapper {
        width: 100%;
      }
    }

    .col-header {
      margin-bottom: 16px;
      font-size: 12px;
      font-weight: 500;
    }

    .col-switch-desc {
      font-size: 14px;
      margin-bottom: 0;
      padding-bottom: 4px;
    }

    .ant-row {
      align-items: center;
      margin-bottom: 16px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    .ant-checkbox-wrapper {
      align-items: center;
      margin-top: 0;
      justify-content: center;
      padding-right: 8px;
    }

    .ant-checkbox {
      top: 0;
    }

    .ant-checkbox-inner::after {
      left: 25%;
    }

    .ant-input {
      font-variant-numeric: lining-nums;
    }
  }

  svg {
    margin-left: 8px;
  }
}

.limits-input {
  width: 100% !important;

  div {
    width: 100%;
  }
}

.text-block-md {
  padding-top: 8px;
}

.multiple-select {
  width: 50%;
  height: auto;
}

.icon-vertical-middle {
  vertical-align: middle;
}

.save-button {
  width: 250px;
}

.page-save-button {
  width: 204px;
}

.input-group-label {
  .ant-form-item-label {
    padding-bottom: 16px !important;
  }
}

.ant-picker {
  width: 100%;
}

.ant-btn-default {
  border: 1px solid $main-red-1;
  color: $main-red-1;
}

.ant-btn-primary[disabled] {
  color: $neutral-white;
  background-color: $main-red-5;
}

.ant-input {
  padding: 8px 12px;

  &[disabled] {
    color: $main-black-1-5;
  }
}

// Menu
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  user-select: none;
  box-shadow: none;
  border-radius: 0;
}

.ant-menu-title-content {
  line-height: initial;
}

.ant-menu-title,
.ant-menu-submenu-title {
  white-space: normal;
}

// Menu selected
.ant-menu-item-selected {
  background: $light-gray !important;
  color: $main-black-1 !important;
}

.ant-menu-item::after {
  border-right-width: 0 !important;
}

.ant-menu-item-selected::before {
  border-left: 4px solid $main-red-1;
  content: "";
  height: 40px;
  position: absolute;
  left: 0;
}

//Submenu
.ant-menu-sub.ant-menu-inline {
  background: $neutral-white;
}

//Submenu selected
.ant-menu-submenu-selected {
  color: $main-black-1;
}

// Select
.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 15px;
}

.ant-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  width: 150px;
  background: $neutral-white;
  border-radius: 5px;
  flex-grow: 1;
  height: 100%;
}

.ant-select-selector {
  width: 100%;
}

.ant-select-selection-overflow-item {
  max-width: 25%;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 4px 12px;
  height: unset;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 4px 12px;
}

// Typography
.ant-typography strong {
  font-weight: 600;
}

h1.ant-typography {
  font-size: 38px;
  margin-bottom: 48px;
  @media (max-width: 1024px) {
    font-size: 30px;
    margin-bottom: 18px;
  }
}

h2.ant-typography {
  color: $text-blue;
  font-style: normal;
  font-weight: 410;
  font-size: 38px;
  line-height: 46px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

h4.ant-typography {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  @media (max-width: 1024px) {
    margin-bottom: 16px;
  }
}

h5.ant-typography {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
  font-weight: 600;
}

// Typography.Paragraph
div.ant-typography {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;

  p:not(:nth-child(1)) {
    margin-top: 12px;
  }
}

// Tabs
.ant-tabs {
  overflow: visible;
}

// Inputs
.ant-input-number-sm {
  border-radius: 6px;
}

.ant-input-number {
  width: 100%;
  max-width: 100% !important;
  padding: 4px 0;
  height: unset;
}

.ant-input-number-group-wrapper {
  max-width: 500px !important;

  .ant-input-number-group {
    .ant-input-number {
      max-width: 100%;
    }
  }
}

.ant-input-number-input {
  padding: 0;
}

.ant-slider-horizontal {
  width: 50%;
}

.ant-slider-track {
  background-color: $main-red-1;
}

.ant-switch {
  margin-right: 8px;
}

// Alert
.ant-alert-with-description .ant-alert-message {
  font-size: 14px;
  font-weight: 600;
}

// Message
.ant-message-notice-content {
  word-break: break-word;
}

// Modal
.ant-modal-close {
  display: none;
}

.ant-modal-header {
  padding: 24px 32px 16px 32px;
  border-bottom: unset;
}

.ant-modal-body {
  padding: 16px 32px 16px 32px;
}

.ant-modal-footer {
  padding: 16px 32px 24px 32px;
  border-top: unset;
}

// ModalConfirm
.ant-modal-confirm-btns {
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    .ant-btn {
      margin-left: 0 !important;
      width: 100%;

      &:not(&:first-child) {
        margin-top: 8px;
      }
    }
  }
}

// Button
.ant-btn-sm {
  border-radius: 6px !important;
}

.ant-btn-link:focus,
.ant-btn-link:hover {
  color: #f26d81 !important;
}
//Tabs
.ant-tabs-tab-btn {
  color: black !important;
}

.ant-tabs-ink-bar {
  background-color: $main-red-1;
  width: 100%;
}

.ant-tabs-nav-list,
.ant-tabs-tab,
.ant-tabs-tab-btn {
  text-align: center;
  width: 100%;
}

.ant-tabs-nav-operations {
  display: none !important;
}

// forms
.ant-form-vertical .ant-form-item-label {
  color: $main-black-2;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;

  .ant-form-item-tooltip {
    margin-left: 8px;
  }
}

.ant-form-item-extra .alert-div {
  color: $alert-gray;
}

.ant-form-item .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content .ant-form-item {
  margin-bottom: 0;
}

//Drawer
.ant-drawer-title {
  color: $text-blue;
  font-style: normal;
  font-weight: 410;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.ant-drawer-body {
  max-width: 100%;
}

.ant-layout {
  overflow-x: hidden;
}

.ant-checkbox-inner {
  width: 24px;
  height: 24px;
  left: calc(50% - 24px / 2);
  top: calc(50% - 24px / 2);
  background: $neutral-white;
  border: 1.2px solid $button-border-gray;
  border-radius: 4px;
}

.ant-checkbox {
  .ant-checkbox-inner {
    border-radius: 6px;
    width: 24px;
    height: 24px;
  }
  .ant-checkbox-inner::after {
    width: 7px;
    height: 13px;
    border: 3px solid #fff;
  }

  .ant-checkbox-group {
    gap: 10px !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-top: 0 !important;
  border-left: 0 !important;
  margin-top: -1px;
  margin-left: 1px;
}

//steps
.ant-steps-item-finish {
  .ant-steps-item-icon {
    border-color: $button-done !important;
    background-color: $button-done !important;

    > .ant-steps-icon {
      color: $neutral-white !important;
    }
  }
}

.ant-steps-item-active {
  .ant-steps-item-icon {
    border-color: $text-blue !important;

    > .ant-steps-icon {
      color: $neutral-white !important;
    }
  }

  .ant-steps-item-description .rectangle-done {
    background: $text-blue !important;
  }
}

.ant-steps-item-container:has(
    .ant-steps-item-content > .ant-steps-item-description > div[class*="styles_rectangle-active"]
  ) {
  > .ant-steps-item-icon {
    border-color: $main-red-5 !important;
    background-color: $main-red-5 !important;
    > .ant-steps-icon {
      color: $neutral-white !important;
    }
  }

  .ant-steps-item-content .ant-steps-item-title {
    font-weight: bold;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: $neutral-white;

  .ant-steps-icon {
    color: $text-blue !important;
  }
}

.ant-steps-item-wait .ant-steps-item-container .ant-steps-item-icon {
  background-color: $button-gray !important;
  border: none;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $button-gray !important;
}

.ant-steps-item-finish .ant-steps-item-container:has(.rectangle-active) .ant-steps-item-icon {
  border-color: $main-red-1;
  background-color: $main-red-1;
}

//ant icon circle filled
.anticon.anticon-question-circle > svg {
  color: $icon-background-gray;
  margin-left: 8px;
  margin-right: 8px;
}

html:not([data-scroll="0"]) {
  header {
    box-shadow: 0 0 5px $main-black-9;
  }
}

a {
  color: $main-red-1;
  text-decoration: none;
  background-color: initial;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.ni-tabs {
  .ant-tabs-nav {
    margin-bottom: 24px;
  }
  .ant-tabs-nav-list {
    flex-wrap: wrap;
  }

  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-nav::before {
    border: none;
  }
  .ant-tabs-nav {
    align-self: flex-start;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    gap: 12px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab-btn {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-shadow: none;
  }

  .ant-tabs-tab,
  .ant-tabs-tab-active {
    height: 34px;
    border-radius: 8px;
    padding: 0 12px;
  }

  .ant-tabs-tab {
    background: #efefef;
    width: fit-content;
    .ant-tabs-tab-btn {
      color: #8a8a8d !important;
      width: 100%;
      margin: 0 12px 0 0;
      color: #8a8a8d !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:last-child {
        margin: 0;
      }
    }
  }

  .ant-tabs-tab-active {
    background: #f6d5db;
    .ant-tabs-tab-btn {
      color: #e64360 !important;
    }
  }
}

.ant-switch {
  margin-right: 0 !important;
}

.ant-tooltip-inner {
  color: #fff !important;
}

.ant-select-item-option-active:hover .ant-select-item-option-content {
  color: unset !important;
  font-weight: unset !important;
}

.ant-select-item-option-content {
  color: unset !important;
}

.ant-collapse-header {
  align-items: center !important;
}

ul {
  padding-inline-start: 25px;
}

.text-primary {
  color: $main-red-1 !important;
  &:disabled {
    color: $main-black-9 !important;
  }
}

.text-secondary {
  color: $text-blue !important;
  &:disabled {
    color: $main-black-9 !important;
  }
}

.currency-suffix {
  color: $main-black-7;
  padding-left: 3px;
}

.input-info {
  font-size: 13px;
  opacity: 0.7;
}

div:has(.tooltip-title) {
  margin-right: 0;
}

.ant-radio-wrapper-disabled {
  color: rgba(27, 27, 32, 0.45);
}

.ant-checkbox-disabled + span {
  color: rgba(27, 27, 32, 0.45);
}
