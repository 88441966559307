@import "../../../../../common/ui/src/lib/scss/variables/colors";

.buttons {
  display: flex;
  gap: 24px;
}

.user-drawer {
  :global {
    .ant-drawer-close {
      margin-right: 28px !important;
    }
  }
}
