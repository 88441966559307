@import "../../../../scss/variables/colors";

.settings-icon {
  font-size: 16px !important;
  color: $button-red-active;
}

.settings-icon:hover {
  cursor: pointer;
}

.settings-modal-options-container {
  padding: 8px;
}
