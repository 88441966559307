@import "../../../../../common/ui/src/lib/scss/variables/colors";

.buttons {
  display: flex;
  gap: 24px;

  .event-sub-group-save-button {
    width: 204px;
  }
}

.event-sub-group-editing-container {
  width: 100%;
  padding: 0 80px;
}
