@import "../../../../../common/ui/src/lib/scss/variables/colors";

.tenant-product-card {
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 437px;
  min-width: 351px;
  background: $neutral-white;
  border-radius: 8px;
  padding: 22px 20px;
  border: 1px solid $button-border-gray;
  min-height: 308px;

  &-heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
  }

  &-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 410;
    font-size: 16px;
    line-height: 19px;
    color: $main-black-3;
  }

  &-options {
    position: relative;
    margin-right: 0;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  &-body {
    display: inline-flex;
    flex-direction: row;
    gap: 24px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    button {
      margin-top: 16px;
      width: 100%;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &-image {
    position: relative;
    max-width: 149px;

    :global {
      .card-disabled {
        opacity: 0.5;
      }
    }
  }

  &-data {
    display: flex;
    flex-direction: column;
    width: 60%;

    font-size: 14px;
    line-height: 20px;

    gap: 9px;

    &-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: baseline;
    }

    &-column {
      display: flex;
      flex-direction: column;

      &-val {
        margin-left: auto;
      }
    }

    &-ips {
      display: flex;
      align-items: center;

      &-logo {
        margin-left: 8px;
      }
    }

    &-label {
      font-weight: 420;
      line-height: 20px;
      color: $main-black-3;
    }

    &-value {
      min-height: 20px;
      margin-left: 4px;
    }
  }
}

.grayed-div {
  background: $neutral-white;
  border: 1px solid $text-dark-gray;
  border-radius: 100px;
  color: $text-dark-gray;
  padding: 0 8px;
  width: fit-content;
  font-size: 11px;
  line-height: 20px;
  font-weight: 500;

  &::first-letter {
    text-transform: uppercase;
  }
}

.card-image {
  position: relative;
  top: 0;
  left: 0;
}
