@import "../../scss/variables/colors";

.dynamic-switch-form {
  overflow: hidden;
  border-color: #e4e4e4;
  
  :global {
    .ant-card-body {
      padding: 0;
    }
  }

  &-header {
    padding: 12px 16px;
    background: #f6f6f6;
    border-bottom: 1px solid #e4e4e4;

    div:nth-child(2) div > div {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &-body {
    padding: 16px;

    :global {
      .ant-row:nth-child(1) {
        span {
          font-size: 13px;
        }
      }
    }
  }

  &-input {
    :global {
      .ant-space-item, .ant-form-item {
        width: 100%;
      }
    }
  }
}