.m {
  &-l {
    &-0 {
      margin-left: 0;
    }

    &-8 {
      margin-left: 8px;
    }

    &-16 {
      margin-left: 16px;
    }

    &-24 {
      margin-left: 24px;
    }

    &-40 {
      margin-left: 40px;
    }
  }

  &-r {
    &-0 {
      margin-right: 0;
    }

    &-8 {
      margin-right: 8px;
    }

    &-16 {
      margin-right: 16px;
    }

    &-24 {
      margin-right: 24px;
    }

    &-40 {
      margin-right: 40px;
    }
  }

  &-t {
    &-0 {
      margin-top: 0;
    }

    &-8 {
      margin-top: 8px;
    }

    &-16 {
      margin-top: 16px;
    }

    &-24 {
      margin-top: 24px;
    }

    &-40 {
      margin-top: 40px;
    }
  }

  &-b {
    &-0 {
      margin-bottom: 0;
    }

    &-8 {
      margin-bottom: 8px;
    }

    &-16 {
      margin-bottom: 16px;
    }

    &-24 {
      margin-bottom: 24px;
    }

    &-40 {
      margin-bottom: 40px;
    }
  }
}