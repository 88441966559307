@import "../../scss/variables/colors";

.CardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border: 1px solid $main-black-9;
  border-radius: 8px;

  .InnerContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    .TextContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      .ProductName {
        font-size: medium;
        font-weight: bold;
      }

      .ProductType {
        font-size: small;
        color: $text-dark-gray;
      }
    }
  }
}
