@import "../../scss/variables/colors";

.dropdown {
  &-input { padding: 12px }
  &-divider { margin: 0 }

  &-menu {
    box-shadow: none !important;
    max-height: 260px;
    overflow-y: auto;
  }

  &-empty {
    width: 100%;
  
    :global {
      .ant-empty { padding: 12px; }
      .ant-empty-image { height: 80px !important }
    }
  }
}

