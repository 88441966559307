@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.details-form {
  max-width: 500px;

  &-switch {
    display: flex;
    align-items: baseline;
  }
}

.details-form-tooltip-text-wrapper {
  display: flex;
  white-space: nowrap;
  gap: 8px;
  align-items: center;
}
