@import "../../scss/variables/colors";

.pct-list {
  width: 100%;

  :global {
    .ant-form-item,
    .ant-select {
      margin: 0;
    }
  }

  .action-button {
    :global {
      span.anticon {
        padding: 0;

        &.anticon-edit, 
        &.anticon-delete {
          font-size: 16px;
        }
      }
    }
  }
}