.row-dragging {
    background: #FBFBFB;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding-left: 18px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.row-dragging .drag-invisible {
    visibility: hidden;
}