@import "../../scss/variables/colors";

.card-panel {
  border: 1px solid rgb(226, 226, 227);
  border-radius: 10px;
  overflow: hidden;

  &-header {
    padding: 16px 16px 0px;

    * {
      margin-bottom: 0 !important;
    }

    :global {
      .ant-form-item-control-input {
        min-height: unset;
      }
    }
  }

  &-content {
    padding: 16px;

    &-container-1 {
      flex: 1;

      &.is-vertical {
        :global {
          .ant-typography {
            margin-right: 16px;
          }
        }
      }
    }

    &-container-2 {
      flex: 2;
      overflow: hidden;
    }
    
    &-value {
      font-size: 13px;
      line-height: 1.75;

      &.is-vertical {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  &-footer {
    height: 32px;
    border-top: 1px solid $light-gray-1;

    a, button {
      flex: 1;
      font-size: 13px;
      border-radius: 0;
      color: rgb(183, 183, 185);

      &:not(:last-of-type) {
        border-right: 1px solid $light-gray-1;
      }

      &:hover {
        color: $main-red-1 !important;
      }
    }
  }
}
