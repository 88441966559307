@import "../../scss/variables/colors";

.payment-scheme-form {
  :global {
    .ant-form-item-explain-error {
      margin-top: 6px;
      transition: all 0.5s 1.5s;
      
      &:not(:first-child) {
        display: none;
      }
    }
  }
}

.payment-scheme-table {
  :global {
    td.ant-table-cell {
      &:not(:nth-child(1)) {
        padding: 0 !important;
      }
    }
  }

  .payment-scheme-input {
    height: 38px;
    border-color: transparent;
    border-radius: unset;

    &:hover {
      border-color: $text-red
    }
  }
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-size: 13px;
  line-height: 20px;
}
