@import "../../../../../common/ui/src/lib/scss/variables/colors";

.sms-general-settings-container {
  max-width: 700px;

  :global {
    .section-title {
      color: $text-blue;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 24px;
    }
  }

  .sms-general-settings-select-item {
    max-width: 504px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .section-description {
    font-size: 14px;
    line-height: 22px;
  }

  .sms-general-settings-form-item {
    align-items: center;
    display: flex;

    :global {
      .tooltip-icon {
        margin-left: 8px;
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-control-input-content {
        button {
          margin-right: 18px;
        }
      }
    }
  }

  .sms-general-settings-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 16px;
    border: 1px solid $main-black-10;
    border-radius: 7px;
    //margin-bottom: 16px;
    min-width: 700px;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 16px;
      gap: 10px;
      width: 100%;
      background: $main-black-13;
      border: 1px solid $main-black-10;
      border-radius: 7px 7px 0 0;
      font-style: normal;
      font-weight: 420;
      font-size: 16px;
      line-height: 24px;
      color: $text-black;
      .header-switch {
        margin-bottom: 0 !important;
      }
    }

    &-body {
      width: 100%;
      padding-left: 12px;
    }

    svg {
      margin-left: 8px;
    }
  }
}

.sms-general-settings-submit-button {
  min-width: 250px;
}

.coming-soon {
  width: 25%;
}
