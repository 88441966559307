@import "../../../../../common/ui/src/lib/scss/variables/colors";

.draggable-phases {
  display: grid;
  gap: 24px;

  .empty-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $text-dark-gray;
  }

  :global {
    &-buttons {
      display: grid;
      gap: 24px;

      .one-line-buttons {
        display: flex;
        gap: 24px;

        .button {
          width: 238px;
          height: 40px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 9px;
        }
      }
    }
  }
}
