.flex {
  display: flex;

  &-wrap {
    flex-wrap: wrap;
  }

  &-row {
    flex-direction: row;

    &-reverse {
      flex-direction: row-reverse;
    }
  }

  &-column {
    flex-direction: column;

    &-reverse {
      flex-direction: column-reverse;
    }
  }

  &-justify {
    justify-content: stretch;

    &-start {
      justify-content: flex-start;
    }

    &-center {
      justify-content: center;
    }

    &-evenly {
      justify-content: space-evenly;
    }

    &-end {
      justify-content: flex-end;
    }

    &-around {
      justify-content: space-around;
    }

    &-between {
      justify-content: space-between;
    }

    &-left {
      justify-content: left;
    }

    &-right {
      justify-content: right;
    }
  }

  &-align {
    align-items: stretch;

    &-start {
      align-items: flex-start;
    }

    &-end {
      align-items: flex-end;
    }

    &-center {
      align-items: center;
    }

    &-baseline {
      align-items: baseline;
    }
  }
}
