@import "../../../../../common/ui/src/lib/scss/variables/colors";

.lty-action-button {
  color: $main-red-1 !important;
}

:global {
  .row-dragging {
    font-family:
      Gotham,
      -apple-system,
      BlinkMacSystemFont,
      "Nunito Sans",
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      "Noto Sans",
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji";
    font-size: 14px;
    font-weight: normal;

    color: rgba(27, 27, 32, 0.88);

    .ant-table-cell {
      padding: 16px 16px !important;
    }
  }
}
