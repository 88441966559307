@import "../../../../../common/ui/src/lib/scss/variables/colors";

.workflow-details {
  background-color: $neutral-white;
  padding: 30px 40px;
  min-height: var(--content-height);

  &-settings-buttons {
    margin-left: auto;
    display: inline-flex;
    gap: 24px;

    :global {
      .button {
        cursor: pointer;
        color: $button-red-active;

        &:hover,
        &:focus {
          color: #f26d81;
        }

        &.disabled {
          cursor: not-allowed;
          pointer-events: all !important;
        }
      }
    }
  }

  &-switch-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }

  &-form {
    max-width: 500px;

    &-switch {
      display: flex;
      align-items: baseline;

      :global {
        .ant-form-item {
          margin-right: 4px;
          margin-bottom: 40px;
        }
      }
    }
  }
}

:global {
  .ant-select {
    border-radius: 8px !important;
  }
}
