@import "../../scss/variables/colors";

.checkbox-indeterminate {
  :global {
    .ant-checkbox-inner {
      &::after {
        width: 12px !important;
        height: 3px !important;
        border: none !important;
      }
    }
  }
}

.checkbox-group-container {
  padding-top: "14px";
}
