@import "../../../../../../../common/ui/src/lib/scss/variables/colors";

.static-product-grey {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px 16px 16px;
  gap: 8px;
  height: inherit;
  background: $background-light-gray;
  border-radius: 9px;
  margin-right: 24px;
}

.static-product-grey-mini-text {
  align-items: center;
  display: flex;
  font-style: normal;
  font-weight: 420;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: $main-black-0;
}
