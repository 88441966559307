@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.list-item {
  display: flex;
  max-width: 700px;

  :global {
    .ant-card-bordered {
      border-color: rgb(226, 226, 227) !important;
    }
    ul > li {
      margin: 0px 0px !important;
    }
  }

  .pct-external-code {
    color: $text-dark-gray;
    font-size: 16px;
    font-weight: 325;
    margin-right: 16px;
    line-height: 31px;
    min-width: 40px;
  }

  .pct-displayed-name {
    font-size: 20px;
    font-weight: 410;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .pct-delete-icon {
      font-weight: unset;
      font-size: 18px;
      cursor: pointer;
      color: $icon-background-gray;
    }

    .pct-delete-icon:hover {
      font-weight: 400px;
      color: $main-red-1;
    }
  }

  .pct-icon {
    color: $icon-background-gray;
  }

  .pct-icon:hover {
    color: $main-red-1 !important;
  }

  .pct-button {
    min-width: 90px;
    min-height: 24px;
    margin-top: 2px;

    &.rename {
      margin-right: 24px;
    }

    &.edit {
      margin-right: 4px;
      margin-left: 8px;
    }

    &.duplicate {
      margin-left: 24px;
    }
  }
}
