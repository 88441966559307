@import "../../../scss/variables/colors";

.checkbox {
  :global {
    .ant-checkbox {
      width: 24px;
      height: 24px;
    }

    .ant-checkbox-input {
      height: 24px !important;
      width: 24px !important;
    }

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;

      &::after {
        width: 8px;
        height: 14px;
      }
    }
  }
}

.coloredLabel {
  color: $text-red;
  margin-bottom: 16px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(68vh);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
}

.spinContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.main-check-for-all {
  label {
    margin-bottom: 0;
  }
}

.link-container {
  position: relative;
  display: inline;
  :global {
    .ant-menu {
      box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.1),
        0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.names-container {
  position: absolute;
  width: 256px;
  z-index: 99;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 150px;
  :global {
    .ant-menu-item-group-title {
      color: $text-black;
      padding: 8px 8px;
    }
  }
}

.ni-loyalty-program-details {
  :global {
    .ant-table-cell-ellipsis {
      overflow: visible;
    }
  }
}

.text-names-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
