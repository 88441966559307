@import "../../../../../common/ui/src/lib/scss/variables/colors";

.tags {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  row-gap: 8px;
  column-gap: 4px;

  :global(.ant-tag.ant-tag-has-color) {
    color: #313136 !important;
  }
}
