@import "../../scss/variables/colors";

.tooltip-text-wrapper {
  display: flex;
  margin-right: 20px;
  gap: 8px;
  max-width: 100%;
  width: 100%;

  .tooltip-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global {
    .anticon.anticon-question-circle > svg {
      margin: 0;
    }
  }
}

.large-label-tooltip-text-wrapper {
  display: inline;
  margin-right: 6px;
  gap: 8px;
  align-items: center;

  .tooltip-text {
    display: inline;
  }

  .tooltip-text-icon-text-wrapper {
    display: inline-flex;
    white-space: nowrap;
    gap: 8px;
  }

  :global {
    .anticon.anticon-question-circle > svg {
      margin: 0;
    }
  }
}
