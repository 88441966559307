@import "../../scss/variables/colors";

.multiple-select-window {
  background: $neutral-white;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: $main-black-10;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: row;
  padding: 30px 40px 30px;

  .count-items {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $text-black;
  }

  .action-buttons {
    display: flex;
    margin-left: auto;
    gap: 40px;


    :global{
      .ant-btn {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        color: $text-red;
        cursor: pointer;
        padding: 0;
        height: 20px;

        .button-close {
          color: $main-black-6;

          &:hover {
            color: #f26d81;
          }
        }
      }
    }
  }
}
