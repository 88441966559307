@import "../../../../../common/ui/src/lib/scss/variables/colors";

.extra {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 0;
  margin-left: auto;

  .ant-input-affix-wrapper {
    height: 40px;
  }
}

.empty-container {
  text-align: center;
  width: 100%;

  .info-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    :global {
      h2.ant-typography {
        color: $text-dark-gray;
        display: initial;
        font-weight: normal;
        max-width: 300px;
      }
    }

    .info {
      max-width: 700px;

      button {
        width: 205px;
      }
    }
  }
}
