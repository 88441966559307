.w {
  &-auto {
    width: auto;
  }

  &-p {
    &-25 {
      width: 25%;
    }

    &-50 {
      width: 50%;
    }

    &-75 {
      width: 75%;
    }

    &-100 {
      width: 100%;
    }

    &-125 {
      width: 125%;
    }

    &-150 {
      width: 150%;
    }
  }
}
