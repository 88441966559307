@import "../../../../../common/ui/src/lib/scss/variables/colors";

.product-details-form {
  max-width: 700px;
}

.product-details-form-input {
  max-width: 450px;
}

.product-details-switch {
  display: flex;
  align-items: baseline;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.details-wrapper {
  background-color: white;
  padding: 30px 40px;
  min-height: var(--content-height);
}

.details-form {
  max-width: 500px;
}

