@import "../../scss/variables/colors";

.container {
  display: flex;
  align-items: center;

  .number-container {
    border: 1px solid $main-black-9;
    border-radius: 8px;
    padding: 8px;
    height: 40px;
    text-align: center;
    width: 40px;
    margin-right: 8px;
  }

  .icons {
    display: flex;
    flex-direction: column;

    :global .ant-btn-text {
      padding: 0;
      height: auto;
      color: $text-dark-gray;
    }
  }

  .text {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-left: 24px;
  }
}
