@import "../../../../../common/ui/src/lib/scss/variables/colors";

.fi-table {
  padding: 40px;
  background-color: $neutral-white;

  tr:nth-child(2n + 1) td {
    background-color: $main-black-14;
  }

  :global {
    .table-row-grayed .ant-table-cell,
    .actions-cell-grayed button {
      cursor: not-allowed;
      pointer-events: none !important;
      opacity: 0.5;
    }

    .ant-dropdown-menu-title-content span {
      line-height: 20px;
    }

    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }
}
