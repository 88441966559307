@import "../../../../../common/ui/src/lib/scss/variables/colors";

.event-form {
  display: grid;

  .empty-pages {
    font-weight: 325;
    font-size: 14px;
    line-height: 17px;
    color: $text-dark-gray;
  }

  &-inputs {
    display: flex;

    .element-input {
      width: calc(50% - 8px);
      margin-bottom: 0;

      &.long {
        flex: 1;
      }

      &.btn {
        width: 100%;
      }
    }

    &.button {
      margin-left: auto;
    }

    &.dictionary-values {
      width: calc(50% - 8px);

      .dictionary-values-btn {
        width: 100%;
        flex: 1 50%;
        height: 40px;
        border: 1px solid $main-black-9;
        border-radius: 8px;
        display: flex;
        align-items: center;
        color: $text-black;

        .text {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }

        .icon {
          margin-left: auto;
        }
      }
    }
  }

  &-buttons {
    display: flex;
    grid-gap: 24px;
    gap: 24px;

    .button {
      width: 122px;
      padding: 0;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 20px;

      display: flex;
      gap: 8px;
    }
  }

  .block {
    background-color: $main-black-14;
    border-radius: 16px;
    padding: 16px;
    display: grid;

    .one-line {
      display: flex;
      gap: 24px;

      :global {
        .ant-form-item {
          width: 50%;
        }
      }
    }
  }

  .event-save-button {
    width: 204px;
  }

  .element-save-button {
    width: 204px;
  }

  .block-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $text-blue;
    text-transform: uppercase;
  }

  .validation-item {
    background: $main-black-15;
    border: 1px solid $main-black-9;
    border-radius: 8px;
    padding: 16px;
    display: grid;
    grid-template-columns: 2fr 2fr 56px;
    gap: 24px;

    .validation-type {
      border-right: 1px solid $main-black-8;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $text-black;
      display: flex;

      .validation-value {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: $main-black-6;
        margin-left: 16px;
      }
    }

    .message {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: $main-black-6;
    }

    .actions {
      display: flex;
      gap: 24px;
      .button {
        cursor: pointer;
        color: $button-red-active;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
          color: #f26d81;
        }
      }
    }
  }

  .hidden {
    display: none;
  }
}
