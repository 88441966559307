@import "../../../../../common/ui/src/lib/scss/variables/colors";

.wizard-range-select {
  display: flex;
  flex-direction: row;
}

.wizard-grey-mini {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 25%;
  height: inherit;
  background: $background-light-gray;
  border-radius: 9px;
  margin-right: 24px;

  :global {
    .anticon {
      padding-right: 8px;
    }
  }
}

.wizard-white-mini {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 20%;
  height: inherit;
  border-radius: 9px;
  margin-right: 24px;

  .wizard-grey-mini-text {
    display: flex;
    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: $main-black-1;
  }
}
