@import "../../scss/variables/colors";

.input-range-slider {
  .input-group {
    display: flex;
    width: 100%;
    & > * {
      width: 100%;
    }
  }

  .icons-slider-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    :global {
      .ant-slider-horizontal {
        width: 100%;
      }
    }
  }

  .slider-icon {
    font-size: 24px;
    color: $main-red-1;
    border-radius: 5px;

    &.slider-icon-disabled {
      color: gray;
      cursor: not-allowed;
    }
  }
}
