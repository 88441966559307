@import "../../../../../common/ui/src/lib/scss/variables/colors";

.fi-table {
  padding: 40px 40px 80px 40px;
  background-color: $neutral-white;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  tr:nth-child(2n + 1) td {
    background-color: $main-black-14;
  }

  &-view {
    :global {
      .ant-table-tbody {
        .table-view-fi-name {
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 30px;
          height: 30px;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  :global {
    .ant-dropdown-menu-title-content span {
      line-height: 20px;
    }

    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }

  .link-action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }
}
