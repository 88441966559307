@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.form-container {
  width: 700px;
  padding: 40px 80px 0;

  .option-group {
    background: $main-black-14;
    border-radius: 16px;
    display: flex;
    column-gap: 24px;
    padding: 16px;
    margin-top: 42px;

    :global .ant-form-item {
      flex-grow: 2;
      margin-bottom: 0;
    }

    :global .anticon {
      color: $text-red;

      &.anticon-delete {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
      }
    }
  }
}

.add-value-button {
  color: $text-red;
  margin-left: 80px;
  margin-top: 8px;

  :global .anticon-plus {
    font-size: 16px;
  }

  &:focus,
  &:hover {
    color: $text-red;
  }
}

.save-button {
  padding-left: 80px;
  padding-right: 80px;
}
