@import "../../../../../common/ui/src/lib/scss/variables/colors";

$page-height-padding: 30px;
$page-left-padding: 56px;
$block-codes-list-padding: 15px;

.event-group-settings-wrapper {
  padding-left: $page-left-padding;
  padding-bottom: $page-height-padding;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;

  .form {
    display: grid;
    gap: 24px;
    padding-bottom: 24px;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $text-blue;
    text-transform: uppercase;
    padding-bottom: 24px;
  }
}
