@import "../../../../../common/ui/src/lib/scss/variables/colors";

.buttons {
  display: flex;
  gap: 24px;

  .event-group-save-button {
    width: 204px;
  }
}

.event-group-editing-container {
  max-width: 660px;
  width: 100%;
}

:global {
  .ant-drawer-close {
    margin-right: 50px;
  }
}
