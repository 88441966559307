@import "../../scss/variables/colors";

.space {
  display: flex;
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-radio-group {
      margin-top: 0;
    }
  }
}
