@import "../../scss/variables/colors";

.after-wizard-edit-page-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right-column {
    display: flex;
    gap: 40px;

    .back-to-dashboard-link {
      color: $text-dark-gray;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tenant-back-icon {
      margin-left: 8px;
    }
  }
}

.after-wizard-edit-page-text {
  margin-top: 16px;
}

.edit-button-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 902;
  margin: 50px;
  padding: 12px;
  :global {
    button.ant-btn[disabled] {
      .anticon {
        color: $text-dark-gray;
      }
    }
  }
}
