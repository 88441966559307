@import "../../../../../common/ui/src/lib/scss/variables/colors";

.UsersLayout {
  display: flex;
  flex-direction: column;

  .title {
    padding: 40px 40px 0 40px;
  }
}
