@import "../../../../common/ui/src/lib/scss/variables/colors";

.container {
  width: 100%;
}

.body {
  padding: 8px 40px 6px;

  :global .ant-collapse {
    background: $main-black-15;
    border-radius: 20px;
    margin-top: 16px;
    width: 700px;

    .ant-collapse-header {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding: 24px;
    }

    .ant-collapse-expand-icon {
      color: $text-blue;
    }

    .ant-collapse-content-box {
      padding: 0 24px 24px;
    }
  }

  .collapse-extra {
    color: $text-red;
    font-size: 20px;
  }
}
