@import "../../../../../../common/ui/src/lib/scss/variables/colors";

$element-height-padding: 30px;
$element-left-padding: 80px;
$block-codes-list-padding: 15px;

.element-settings-wrapper {
  padding-left: $element-left-padding;
  padding-bottom: $element-height-padding;
  overflow-y: auto;
  width: 50%;
  overflow-x: hidden;

  .element-select-item {
    max-width: 600px;
  }

  .tab-wrapper {
    margin-top: 20px;

    &-switch {
      display: flex;
      align-items: baseline;
    }

    .inner-item {
      margin-top: 20px;
    }
  }

  .element-collapse-text {
    font-size: 16px;
    font-weight: 500;
  }

  //Collapse customization form editing style
  :global {
    .ant-collapse {
      background-color: white;
    }

    .ant-collapse,
    .ant-collapse-header,
    .ant-collapse-item,
    .ant-collapse-content {
      border: 0 !important;
      font-size: 16px;
    }

    .ant-collapse-expand-icon {
      color: $text-blue !important;
    }

    .ant-collapse-item {
      background-color: white;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
      margin: 24px 0 0 0;
      border: none;
      display: grid;
      gap: 24px;

      .form-item {
        margin: 0;
      }
    }

    .ant-collapse-header {
      padding-left: 5px !important;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }

    .ant-collapse-extra {
      margin-left: 5px !important;

      svg {
        height: 12px;
        width: 12px;
        margin-bottom: 1px;
        color: $icon-background-gray;
      }
    }
  }
}

.settings-group {
  display: flex;
  gap: 24px;
}

.settings-group > div {
  flex: 1 1 auto;
  text-align: center;
  margin: 5px; /* and that, will result in a 10px gap */
}

.element-settings-form-switch {
  display: flex;

  .text {
    color: $text-black;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
  }
}

.element-settings-panel-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: $text-black;
}

.element-content-wrapper {
  width: 50%;
}

.one-row {
  display: flex;
  gap: 24px;

  .form-item {
    flex: 1;
  }
}

.layout {
  display: grid;
  gap: 24px;

  .info {
    padding: 16px;
    background-color: var(--neutral-white);
    border-radius: 16px;
  }

  .add-validation {
    display: flex;
    width: auto;
    align-items: flex-start;
    justify-content: center;
  }

  .buttons {
    display: flex;
    grid-gap: 24px;
    gap: 24px;

    .button {
      width: auto;
      padding: 0;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 20px;
      color: #e64360;

      display: flex;
      gap: 8px;
    }
  }
}
