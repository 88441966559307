@import "../../scss/variables/colors";

.checkbox {
  :global {
    .ant-checkbox {
      width: 24px;
      height: 24px;
    }

    .ant-checkbox-input {
      height: 24px !important;
      width: 24px !important;
    }

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;

      &::after {
        width: 8px;
        height: 14px;
      }
    }
  }
}
