@import "../../../../../common/ui/src/lib/scss/variables/colors";

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
  background: $light-gray;
  border-radius: 16px;

  .example {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  .table-cell-icon {
    color: $main-black-6;
    padding: 8px;
  }

  &-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 24px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    justify-content: space-between;

    &-tag {
      color: $text-blue;
      border-color: $text-blue;
      border-radius: 100px;
    }

    :global(.ant-typography) {
      font-style: normal;
      font-weight: 350;
      font-size: 14px;
      line-height: 24px;
      color: $text-black;
    }

    &-left-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      gap: 8px;
    }

    &-right-col {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;

      :global(.anticon) {
        color: #e64360;
      }
    }
  }
}
