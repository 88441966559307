@import "../../../../../common/ui/src/lib/scss/variables/colors";

.features-container {
  background: $light-gray;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;

  .description { color: $main-black-6; }

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    word-break: break-word;
  }

  .code {
    border-radius: 16px;
    border-color: $text-blue;
    color: $text-blue;
  }

  .actions {
    button { color: $text-red; }
  }
}
