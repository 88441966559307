@import "../../../../../common/ui/src/lib/scss/variables/colors";

.element-template-modal-layout {
  display: flex;
  flex-direction: column;
  width: 100%;

  .extra {
    margin-right: 0;
    margin-left: auto;
    align-items: center;
    overflow-x: hidden;
    display: inline-table;
    :global(.ant-form-item-control-input) {
      min-height: 30px;
    }
  }

  .filters-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    column-gap: 24px;
    :global(.ant-select-selection-placeholder) {
      color: $text-black;
    }
  }

  :global {
    .ant-form-item {
      min-width: 150px;
      flex: 1 1 0;
    }

    .ant-input-affix-wrapper {
      height: 40px;
    }

    .ant-select-selector {
      border: none;
      box-shadow: none !important;
      height: 100%;
    }
  }
  .new-element-template-modal-button {
    height: 40px;
    margin-right: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }
}

.element-template-modal-setting-layout-wrapper {
  display: flex;
  flex-grow: 1;

  .element-template-modal-sidenav {
    width: 300px;
    border-right: 1px solid $main-black-10;

    .element-template-modal-select {
      margin: 24px 10px 16px 10px;
      width: auto;
    }
  }

  .page-content {
    flex: 2;
  }

  .element-template-modal-sidenav .select-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    gap: 8px;

    .select-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $main-black-3;
      margin-bottom: 0;
      margin-left: 5px;
      height: 20px;

      &-code {
        margin-bottom: 0;
        color: $text-dark-gray;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}

.loading-element-template {
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
