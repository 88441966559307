@import "../../../../../common/ui/src/lib/scss/variables/colors";

$block-codes-list-padding: 15px;

.page-settings-wrapper {
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  max-width: 660px;

  &-edit {
    max-width: 100%;

    .one-line {
      display: flex;
      gap: 24px;

      :global {
        .ant-form-item {
          width: 50%;
        }
      }
    }

    .event-sub-group-save-button {
      width: 204px;
    }

    .template {
      margin-top: 50px;

      :global {
        .ant-collapse-header {
          padding: 12px 0;
        }

        .ant-collapse-content-box {
          padding: 12px 0;
        }
      }

      .extra-header {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .event {
      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $text-blue;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .event-list {
        margin-top: 24px;
        display: grid;
        gap: 24px;
      }

      .event-header-block {
        display: flex;
        margin-bottom: 24px;

        .block-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $text-blue;
          text-transform: uppercase;
          padding: 0 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .event-details-form-buttons {
          margin-left: auto;
        }
      }
    }
  }

  .form {
    display: grid;
    gap: 24px;
    padding-bottom: 24px;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $text-blue;
    text-transform: uppercase;
    padding-bottom: 24px;
  }
}
