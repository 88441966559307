@import "../../../../../common/ui/src/lib/scss/variables/colors";

.financial-institutions-layout {
  display: flex;
  flex-direction: column;

  .extra {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto;

    :global {
      .ant-input-affix-wrapper {
        height: 40px;
      }
    }
  }
}
