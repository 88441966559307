@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 380px;
  overflow-y: auto;
}
