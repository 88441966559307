@import "../../../../../common/ui/src/lib/scss/variables/colors";

.consistant-part-table {
  :global {
    th.ant-table-cell {
      border-start-end-radius: 10px 0px !important;
    }
    table {
      border-collapse: collapse !important;
    }
    tbody > tr {
      border-top: 2px solid $main-black-10 !important;
      border-right: 1px solid $main-black-10 !important;
      border-bottom: 2px solid $main-black-10 !important;
    }
    .ant-table-cell-row-hover {
      background-color: $neutral-white !important;
    }
  }
}

.dragable-part-table {
  :global {
    .ant-table-cell {
      border-start-start-radius: 0px !important;
    }
    table {
      border-collapse: collapse !important;
    }
    tbody > tr {
      border-top: 2px solid $main-black-10 !important;
      border-left: 1px solid $main-black-10 !important;
      border-bottom: 2px solid $main-black-10 !important;
    }
  }
}

.lty-action-button {
  color: $main-red-1 !important;
}

.lty-action-button-spacing {
  color: $neutral-white !important;
  background-color: $neutral-white !important;
}

:global {
  .row-dragging {
    font-family:
      Gotham,
      -apple-system,
      BlinkMacSystemFont,
      "Nunito Sans",
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      "Noto Sans",
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji";
    font-size: 14px;
    font-weight: normal;

    color: rgba(27, 27, 32, 0.88);

    .ant-table-cell {
      padding: 16px 16px !important;
    }
  }
}
