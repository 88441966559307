@import "../../scss/variables/colors";

.ni-sidenav {
  background-color: white;
  height: 100vh;
  min-width: var(--sidenav-width);
  top: 0;
  left: 0;
  z-index: 10;

  &-fixed {
    width: calc(var(--sidenav-width) - 1px);
    position: fixed;
    min-height: 100vh;

    &-border {
      border-right: 1px solid $main-black-10;
    }
  }

  &-content {
    border-bottom: 1px solid $main-black-10;
  }

  &-menu {
    border-right: none;
    border-inline-end: none !important;
    max-height: var(--sidenav-height);
    overflow-y: auto;
  }

  :global {
    .ant-select-item-option-content > .sidenav-logo-wrapper {
      align-items: center;
      display: flex;

      .sidenav-logo-label {
        margin-bottom: 0;
        margin-left: 8px;
      }
    }
  }
}

.ni-entity-select {
  width: auto !important;
  margin: 22px 10px 22px 10px;
  border: none;
  padding-left: 0;
  width: 100%;

  :global {
    .ant-menu-title-content {
      text-transform: capitalize;
    }

    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &_logo {
    max-height: 32px;
    max-width: 32px;
  }

  &_label {
    margin-bottom: 0 !important;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
