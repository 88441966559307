@import "../../scss/variables/colors";

.wizard-success {
  &-title {
    margin-bottom: 0 !important;
  }

  &-details {
    :global {
      & h3 {
        font-size: 20px !important;
        font-weight: 500 !important;
        margin-bottom: 0 !important;
      }

      & span {
        color: $main-black-6;
      }
    }
  }
}
