@import "../../../scss/variables/colors";

.payment-scheme-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .payment-scheme-name {
    margin: 0;
    padding-left: 8px;
  }
}