@import "../../scss/variables/colors";

.lty-list-item {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
  gap: 16px;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid #cdcdce;
  padding: 8px 16px 8px 16px;

  .lty-list-body {
    overflow: hidden;
    width: 100%;
  }

  .lty-list-name {
    font-size: 16px;
    font-weight: 500;
    color: $main-black-2;
    margin-bottom: 0;
  }

  .lty-details-title {
    font-size: 12px;
    font-weight: 500;
    color: $text-dark-gray;
  }

  .lty-details-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    margin: 0px 4px;
    font-size: 12px;
    color: $text-dark-gray;
  }
}
