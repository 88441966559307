@import "../../../../../common/ui/src/lib/scss/variables/colors";

.ni-deployment {
  &-details {
    :global {
      .ant-table-tbody > tr > td {
        border-bottom: 0;
      }

      .ant-table-content table {
        border: 1px solid $light-gray-1;
        border-radius: 10px;
      }

      .ant-table-cell.ant-table-cell-ellipsis {
        &::before {
          content: "" !important;
          width: 0 !important;
        }
      }
    }
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
