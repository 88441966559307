@import "../../scss/variables/colors";

.round-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 24px;
  height: 24px;
  background: $text-blue;
  border-radius: 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $text-white;
  margin-right: 16px;

}