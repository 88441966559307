@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.collapsible-list {
  background-color: white;
  border-radius: 0;

  &--bolded {
    .ant-collapse-item > .ant-collapse-header > .ant-collapse-header-text {
      font-weight: bold;
    }
  }

  &--normal {
    .ant-collapse-item > .ant-collapse-header > .ant-collapse-header-text {
      font-weight: normal;
    }
  }
}

.collapsible-list .collapsible-list:last-child {
  border-bottom: 0;
}
