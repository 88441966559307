@import "../../../../../common/ui/src/lib/scss/variables/colors";

.workflow-table {
  padding: 40px 40px 80px 40px;
  background-color: $neutral-white;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .description {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  tr:nth-child(2n + 1) td {
    background-color: $main-black-15;
  }

  .table-cell-icon {
    color: $main-black-6;
    padding: 8px;
    border-radius: 8px;
    background-color: $main-black-11;
  }

  .table-action-icon {
    height: 20px;
    color: $main-black-6;
  }

  .pin-disabled {
    color: $main-black-6;
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &-view {
    :global {
      .ant-table-tbody {
        .table-view-workflow-name {
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 30px;
          height: 30px;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  :global {
    .ant-dropdown-menu-title-content span {
      line-height: 20px;
    }
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }
}
