@import "../../../../../../common/ui/src/lib/scss/variables/colors";

$block-codes-max-width: 1250px;
$block-codes-max-height: 550px;
$block-codes-height-padding: 10px;
$block-codes-list-padding: 15px;

.block-codes-editing-wrapper {
  background-color: white;
  padding-top: $block-codes-height-padding;
  padding-bottom: $block-codes-height-padding;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;

  .block-codes-editing-container {

    .section-title {
      color: $text-blue;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 24px;
      padding-top: 16px;
      text-transform: uppercase;
    }

    .block-codes-editing-status-select-item {
      max-width: 660px;
    }
  }

  .block-codes-switch-form-item {
    align-items: center;
    display: flex;
    margin-bottom: 18px;

    :global {
      .ant-form-item-control-input-content {
        button {
          margin-right: 18px;
        }
      }
    }
  }

  .block-codes-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background: $neutral-white;
    border: 1px solid $border-header;
    border-radius: 7px;
    margin-bottom: 24px;
    margin-top: 40px;
    padding: 0 0 16px;

    .block-codes-table-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 16px;
      gap: 10px;
      width: 100%;
      background: $main-black-13;
      border: 1px solid $border-header;
      border-radius: 7px 7px 0 0;
      font-style: normal;
      font-weight: 420;
      font-size: 16px;
      line-height: 24px;
      color: $main-black-2;

      .table-subheader {
        font-size: 14px;
      }

      .second-column {
        margin-left: 8px;
      }

      .third-column {
        margin-left: 10px;
        flex-basis: 20%;
      }
    }
    .table-error {
      padding-left: 16px;
      font-size: 13px;
      color: red;
    }

    :global {
      .ant-row {
        width: 100%;
        height: 40px;
      }

      .ant-col .ant-space .ant-space-item {
        height: 40px;
        line-height: 40px;
      }

      .ant-checkbox-wrapper {
        padding-top: 5px;
      }
    }

    .table-first-subheader {
      line-height: 40px;
    }

    .via {
      text-transform: uppercase;
      font-size: 12px;
      line-height: normal;
      color: $text-dark-gray;
    }

    .row-name {
      font-size: 13px;
      font-weight: 400;
      padding-left: 16px;
      line-height: normal;
      display: flex;
      flex-direction: column;
    }

    .cell-wrapper {
      margin: 0;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;
    }
  }
}

