@import "../../../../../common/ui/src/lib/scss/variables/colors";

.qp-table {
  :global {
    .ant-checkbox-input {
      padding-right: 5px !important;
    }

    .ant-table-row {
      .ant-table-cell {
        padding: 12px 16px !important;

        &.qp-editing {
          padding: 0 16px !important;
        }
      }

      .ant-input-number {
        padding-left: 0 !important;
      }

      .ant-input-number,
      .ant-input,
      .ant-select-selector {
        height: 38px !important;
      }

      .ant-select-selection-item {
        padding-right: 0 !important;
      }
    }
  }

  .editable-cell-wrapper {
    border-radius: 8px;
    padding: 5px;
    background-color: $light-gray;
  }

  .editable-cell-wrapper:active {
    border-radius: 8px;
    padding: 5px;
    color: $text-dark-gray !important;
    font-size: 16px !important;
  }
}

.qp-confirm-table {
  :global {
    div.ant-table-container {
      border: none !important;
    }
    .ant-checkbox-input {
      padding-right: 5px !important;
    }
    td.ant-table-cell {
      border-inline-end: none !important;
    }
    th.ant-table-cell {
      border-inline-end: none !important;
      background: $neutral-white !important;
    }
    .ant-table-row {
      .ant-table-cell {
        padding: 12px 16px !important;

        &.qp-editing {
          padding: 0 16px !important;
        }
      }

      .ant-input-number {
        padding-left: 0 !important;
      }

      .ant-input-number,
      .ant-input,
      .ant-select-selector {
        height: 38px !important;
      }

      .ant-select-selection-item {
        padding-right: 0 !important;
      }
    }
  }
}
