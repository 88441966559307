@import "../../../../../common/ui/src/lib/scss/variables/colors";

.client-identification-section-wrapper,
.id-expire-wrapper {
  max-width: 700px;
}

.id-expire-wrapper {
  .content-header,
  .content-wrapper {
    width: 700px;
    padding: 16px;
    border: 1px solid $main-black-9;
    gap: 0;
  }

  .content-header {
    border-radius: 8px 8px 0 0;
    border-bottom: none;
    background-color: $main-black-13;

    .header-text {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .content-wrapper {
    border-radius: 0 0 8px 8px;
  }
}

.select-item {
  max-width: 504px;
}

.section-title {
  color: $text-blue;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.section-description {
  font-size: 14px;
  line-height: 22px;
}

.form-item {
  align-items: center;
  display: flex;

  .tooltip-icon {
    margin-left: 8px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-control-input-content {
      button {
        margin-right: 18px;
      }
    }
  }
}

.save-button {
  min-width: 250px;
}

.info {
  background-color: $main-black-15;
  border-radius: 20px;
  padding: 16px;

  .info-link-text {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.text-uppercase {
  text-transform: uppercase;
}
