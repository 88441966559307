@import "../../../../../common/ui/src/lib/scss/variables/colors";

.workflow-setting-layout-wrapper {
  display: flex;
  flex-grow: 1;

  .workflow-sidenav {
    width: 300px;
    border-right: 1px solid $main-black-10;

    .workflow-select {
      margin: 24px 10px 16px 10px;
      width: auto;

      :global {
        .ant-select-selector {
          border: none;
          box-shadow: none !important;
          height: 100%;
        }
      }
    }
  }

  .page-content {
    flex: 2;
  }

  .workflow-sidenav .select-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    gap: 8px;

    .select-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $main-black-3;
      margin-bottom: 0;
      margin-left: 5px;
      height: 20px;

      &-code {
        margin-bottom: 0;
        color: $text-dark-gray;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
