@import "../../../libs/common/ui/src/lib/scss/variables/colors";

.loading-overlay {
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
}

.content-wrapper {
  padding-top: var(--navigation-bar-wrapper-height);
  padding-left: 1px;
  min-height: var(--content-height);
  z-index: 10;
}

:root {
  --navigation-bar-height: 78.59px;
  --navigation-bar-wrapper-height: 80px;
  --content-height: calc(100vh - var(--navigation-bar-wrapper-height));
  --sidenav-height: calc(100vh - 191px);
  --sidenav-width: 300px;

  --fees-input-max-width: 450px;

  --text-black: #313136;
  --neutral-white: #f9f9f9;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 6px;
  background: $main-black-11;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: $icon-background-gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $main-black-6;
}
