@import "../../../../../common/ui/src/lib/scss/variables/colors";

.wizard-checkbox-card {
  border: 1px solid $main-black-9;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 16px;
  width: 100%;
  justify-content: space-between;

  &-content {
    padding-left: 24px;
  }

  &-title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: $main-black-2;
  }

  &-text {
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 17px;
  }

  &-image {
    &.disabled img {
      filter: opacity(0.4);
    }

    img {
      border-left: 1px solid $main-black-9;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 8px;
      height: 100%;
      max-width: 205px;
      object-fit: cover;
    }
  }
}
