@import "../../scss/variables/colors";

.section-title {
  text-transform: uppercase;
  margin-bottom: 0 !important;

  &.type-primary {
    color: $main-red-1;
  }

  &.type-secondary {
    color: $main-blue-1;
  }

  &.type-default {
    color: $main-black-1;
  }
}