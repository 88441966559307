@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.block-item-wrapper {
  height: 24px;
  margin: 0 5px 12px 5px;
  display: flex;
  gap: 20px;
  min-width: 500px;

  .code-style {
    font-size: 18px;
    min-width: 22px;
    margin-left: 20px;
    font-weight: bold;
    line-height: 24px;
  }

  .name-style {
    font-size: 12px;
    padding: 0px 15px 0px 15px;
    line-height: 20px;
    font-weight: bold;
    white-space: nowrap;
  }

  .filled {
    color: $text-blue;
    border: 2px solid $text-blue;
    border-radius: 18px;
  }

  .unfilled {
    color: $text-dark-gray;
    border: 2px solid $text-dark-gray;
    border-radius: 18px;
  }

  .second-section-style {
    display: flex;
    flex: 1;
    justify-content: end;
    gap: 20px;
    min-width: max-content;
    margin-inline: 12px;

    .priority-style {
      width: 90px;
      margin: 5px;
    }

    .edit-button {
      text-align: right;
      color: $main-red-1;
      font-size: 14px;
      line-height: 28px;

      :global {
        .ant-btn {
          border: 0;
          padding: 0 !important;
          max-height: 24px;
        }
      }
    }
  }
}
