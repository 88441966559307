@import "../../../../../common/ui/src/lib/scss/variables/colors";

.ni-point-accrual-setup-table {
  background-color: var(--neutral-white);
  padding: 16px;
  border-radius: 16px;
  width: 100%;

  :global {
    .ant-form-item-label {
      padding-left: 10px;
      padding-bottom: 0;
    }
  }
}
