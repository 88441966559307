@import "../../../../../common/ui/src/lib/scss/variables/colors";

.buttons {
  display: flex;
  gap: 24px;
}

.container {
  width: 100%;
}

.page-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #313136;
  gap: 8px;
  align-items: center;
  width: 80%;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  display: inline-block;
}

.extra-container {
  margin-right: 0;
  margin-left: auto;
  padding: 8px 40px 6px;

  .top-section {
    display: flex;

    .new-button {
      height: 40px;
      margin-right: 24px;
      padding-right: 40px;
      padding-left: 40px;
    }

    .ant-form-item {
      margin-bottom: 0;
      height: 40px;

      .ant-input-affix-wrapper {
        height: 40px;
      }
    }
  }

  .bottom-section {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    .ant-btn-text {
      color: $text-red;
    }
  }
}

.body {
  padding: 8px 40px 6px;
  max-width: 650px;
  width: 100%;

  .dictionary-value-item {
    border: 1px solid $main-black-15;

    &.selected {
      border: 1px solid $main-blue-1;

      .ant-collapse-header-text {
        color: $text-red;
      }
    }

    &-btn {
      &.selected {
        color: $main-blue-1;
      }
    }
  }

  .ant-collapse {
    border-radius: 20px;
    margin-top: 16px;
    width: 700px;

    .ant-collapse-header {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding: 24px;
    }

    .ant-collapse-expand-icon {
      color: $text-blue;
    }

    .ant-collapse-content-box {
      padding: 0 24px 24px;
    }
  }

  .collapse-extra {
    color: $text-red;
    font-size: 20px;
  }
}
