@import "../../../common/ui/src/lib/scss/variables/colors";

.sms-variables-dictionary {
  :global {
    .heading-bar {
      align-items: flex-start;
    }
  }

  display: flex;
  flex-direction: column;
  width: 100%;

  .extra-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto;
    gap: 24px;
    margin-bottom: 24px;

    .top-section {
      display: flex;
      gap: 24px;
      flex-direction: row;

      .new-workflow-button {
        height: 40px;
        padding-right: 24px;
        padding-left: 24px;
      }

      :global .ant-form-item {
        margin-bottom: 0;
        .ant-input-affix-wrapper {
          height: 40px;
        }
      }
    }

    .bottom-section {
      display: flex;
      justify-content: flex-end;
      :global {
        .ant-btn-text {
          color: $text-red;
          display: flex;
          .anticon {
            font-size: 24px;
          }
        }
      }
    }
  }
}
