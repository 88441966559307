@import "../../../../../../common/ui/src/lib/scss/variables/colors";

.header {
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 24px;

  .sync-button {
    width: unset;

    button { height: 40px; }

    :global {
      .ant-btn-compact-first-item {
        padding-right: 25px !important;
        padding-left: 25px !important;
      }
    }
  }

  .new-button {
    height: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }

  :global .ant-form-item {
    margin-bottom: 0;

    .ant-input-affix-wrapper {
      height: 40px;
    }
  }
}
