@import "../../../common/ui/src/lib/scss/variables/colors";

.ni-tenant {
  display: flex;
  flex-grow: 1;

  &-content {
    flex: 2;

    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }
}
