@import "../../scss/variables/colors";

.dataframework-table {
  :global {
    .ant-form-item-control-input {
      min-height: unset !important;
    }

    td.ant-table-cell:nth-child(1) {
      font-weight: 500 !important;
      background-color: #fafafb !important;
    }
  }

  .is-disabled {
    cursor: not-allowed !important;
    background-color: #fafafb !important;
  }

  .editable-cell {
    padding: 0 !important;
  }

  .category-row {
    background-color: #fafafb;

    &[data-row-key^="section"] {
      :global {
        td.ant-table-cell {
          font-weight: 500 !important;
          color: $main-blue-2 !important;
        }
      }
    }

    td {
      cursor: default !important;
    }
  }
}

.dataframework-input {
  border-radius: 0 !important;
  height: 40px !important;
  :global {
    .ant-input-number-input-wrap,
    .ant-input-number-input {
      height: 100% !important;
    }
    .ant-input-number-input {
      padding: 3px 7px !important;
    }
  }
}

.button {
  width: 208px;
}

.expand-all-custom-button {
  border-radius: 50px !important;
  width: 18px !important;
  height: 18px !important;
}
