@import "../../../common/ui/src/lib/scss/variables/colors";

.admin-page {
  display: flex;
  flex-direction: row;
  min-height: var(--content-height);

  .vertical-drawer {
    position: relative;
    min-height: var(--content-height);
    background: $light-gray;
    z-index: 10;

    &-open {
      :global {
        .ant-drawer-content-wrapper {
          box-sizing: content-box;
          width: var(--sidenav-width) !important;
        }

        min-width: var(--sidenav-width) !important;
      }
    }

    :global {
      .ant-drawer-content-wrapper {
        box-shadow: none;
      }

      .ant-menu-inline,
      .ant-menu-vertical,
      .ant-menu-vertical-left {
        border-right: 0;
      }

      .ant-drawer-body {
        overflow: hidden !important;
      }
    }

    :global(.ant-drawer-body) {
      padding: 0 !important;
      .button {
        right: -14px;
        margin-top: 48px;
        border: 1px solid $main-black-10;
      }
    }

    .button {
      position: absolute;
      z-index: 100;
      background: $neutral-white;
      &:global(.ant-btn) {
        color: $text-red;
      }
    }

    .min {
      display: flex;
      justify-content: center;
      margin-top: 48px;
    }
  }
}
