@import "../../../../../common/ui/src/lib/scss/variables/colors";

$block-codes-list-padding: 15px;

.pct-tooltip {
  max-width: 1000px;
}

.pct-subtitle {
  margin-top: 16px;
  max-width: 1024px;
}

.details-wrapper {
  padding: 30px 40px;
}

.pct-editing-wrapper {
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;

  .pct-select-item {
    width: 100%;
    max-width: 500px;

    input {
      text-align: right;
    }
  }

  .tab-wrapper {
    margin-top: 20px;

    &-switch {
      display: flex;
      align-items: baseline;

      &.my-16 {
        margin: 16px 0;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 24px;
      }

      &.mb-0 {
        margin-bottom: 0 !important;
      }
    }
  }

  .inner-item {
    max-width: 500px;
    width: 100%;
  }

  :global {
    .ant-collapse,
    .ant-collapse-header,
    .ant-collapse-item,
    .ant-collapse-content {
      border: 0 !important;
      font-size: 16px;
    }

    .ant-collapse-expand-icon {
      color: $text-blue !important;
    }

    .ant-collapse-item {
      background-color: white;
    }

    .ant-collapse-content-box {
      margin-left: 10px;
      border-left: 1px solid $main-black-10;
      padding: 0 !important;
    }

    .ant-collapse-header {
      padding-left: 5px !important;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }

    .ant-collapse-extra {
      margin-left: 5px !important;

      svg {
        height: 12px;
        width: 12px;
        margin-bottom: 1px;
        color: $icon-background-gray;
      }
    }
  }
}

.pct-drawer-container {
  margin-right: 56px;
}

.pct-save-button {
  width: 204px;
  margin-right: 40px;
}

:global .ant-input-number-group-wrapper {
  width: 100%;
}

.pct-editing-box {
  background-color: $main-black-13;
  padding: 16px;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &-remove_button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &-title {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 0;

    &.lower_case {
      text-transform: none;
    }
  }

  &-input {
    margin-top: 20px;
    max-width: 500px;

    input {
      text-align: right !important;
      padding: 8px 12px;
    }
  }
}

.pct-number-input svg {
  margin-left: 0;
  width: 7px;
  height: 6px;
  color: #c5c5c6;
}

.section__wrapper--gray {
  display: flex;
  padding: 16px;
  background-color: $main-black-13;
  border-radius: 9px;
  width: 100%;
  margin-top: 24px;
}

.transaction-limits {
  :global {
    .ant-col .ant-form-item-control .ant-form-item-control-input,
    .ant-form-item-has-success {
      flex: 1;
    }

    .col-switch,
    .header-switch {
      flex: 0;
    }
  }
}

.balance-inquiry-page {
  .pct-save-button {
    margin-bottom: 20px;
  }

  :global {
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }

    .ant-form-vertical .ant-form-item-label .ant-form-item-tooltip {
      margin: 0;
    }

    .ant-input-number,
    .ant-input-group {
      width: 500px;
      max-width: 500px;
      text-align: right;
    }

    .ant-input-number input {
      text-align: right;
      padding-right: 12px;
    }
  }

  .default-inquiry-fee {
    display: flex;
    flex-direction: column;
    gap: 36px;

    :global {
      .ant-space-vertical {
        gap: 20px !important;
      }
    }
  }

  .customized-inquiry-fees {
    display: flex;
    flex-direction: column;
    gap: 24px;

    :global {
      .ant-space-vertical {
        gap: 20px !important;
      }
    }

    .switch-label {
      color: #313136;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    .switch-label-wrapper {
      display: flex;
      align-items: center;
    }
  }
}

.fees-page {
  .general-fees-inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 500px;
  }

  .fee-switch {
    :global {
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .fee-switch-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  :global .ant-form-vertical.ant-form-item-label {
    padding-bottom: 0;
  }

  .inactivity-fees-inputs-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
  }

  .inactivity-fees-inputs {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 500px;
  }

  .decline-fee-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
  }
}

.pct-lty-form {
  max-width: 100%;
  width: 100%;

  .pct-lty-form-item {
    max-width: 500px;
    width: 100%;
  }
}
