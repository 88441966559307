@import "../../../../../common/ui/src/lib/scss/variables/colors";

.logo-wrapper {
  .logo-label {
    font-style: bold;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $main-black-2;
    margin-bottom: 8px;
  }

  img.tenant-logo {
    height: 92px;
    max-height: 92px;
    max-width: 92px;
  }
}
