@import "../../../common/ui/src/lib/scss/variables/colors";

.phase-details {
  padding: 30px 40px;
  height: 100%;

  &-body {
    margin-top: 40px;
  }
}
