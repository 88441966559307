@import "../../scss/variables/colors";

.shortcut-button-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  margin: 60px;
  padding: 12px;

  :global {
    button.ant-btn[disabled] {
      .anticon {
        color: $text-dark-gray;
      }
    }
  }

  .shortcut-button {
    width: 33px;
    padding: 1px;
  }

  .shortcut-button-icon {
    font-size: 30px !important;
    color: $button-red-active;
  }

  .shortcut-copy-button-icon {
    font-size: 28px !important;
    color: $button-red-active;
  }
}
