@import "../../scss/variables/colors";

.card-view-small {
  width: 52px;
  min-width: 52px;
}

.card-view-medium {
  width: 90px;
  min-width: 90px;
}

.card-view-large,
.card-view-small,
.card-view-medium {
  position: relative;

  .card-image {
    width: 100%;
  }

  .mini-ips {
    position: absolute;
    width: 20%;
    height: 15%;
    top: 10%;
    left: 5%;
  }
}
