@import "../../scss/variables/colors";

.button {
  width: 208px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;

  :global {
    .ant-input-wrapper {
      max-width: 500px;
    }

    .ant-input-number {
      max-width: 500px !important;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-select {
      max-width: 500px;
    }

    .ant-form-item-control-input input[type="text"] {
      max-width: 500px;
    }

    .ant-tabs-content-holder {
      width: 100%;
    }

    .ant-space {
      width: 100%;
    }
  }

  &-sm {
    max-width: 500px;
  }

  &-md {
    max-width: 700px;
  }

  &-full {
    max-width: 100%;
  }
}
