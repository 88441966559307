@import "../../../scss/variables/colors";

.wizard-main {
  display: flex;
  flex-direction: row;

  .progress-bar {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 40px;
    min-width: 20%;

    .rectangle {
      display: inline-block;
      width: 24px;
      height: 6px;
      background: $button-gray;
      border-radius: 2px;
      transform: rotate(-180deg);
      margin-right: 4px;
    }

    .rectangle-done {
      display: inline-block;
      width: 24px;
      height: 6px;
      background: $button-done;
      border-radius: 2px;
      transform: rotate(-180deg);
      margin-right: 4px;
    }

    .rectangle-blue {
      display: inline-block;
      width: 24px;
      height: 6px;
      border-radius: 2px;
      transform: rotate(-180deg);
      margin-right: 4px;
      background: $text-blue;
    }

    .rectangle-active {
      display: inline-block;
      width: 24px;
      height: 6px;
      background: $button-red-active;
      border-radius: 2px;
      transform: rotate(-180deg);
      margin-right: 4px;
    }
  }

  :global {
    .wizard-page {
      border: 40px;
      padding: 30px 40px;
      width: 80%;

      .wizard-form {
        &-smaller-width {
          width: 70%;
          margin-bottom: 40px;

          .ant-form-item {
            margin-bottom: 0;
          }

          .label-text {
            display: grid;
            gap: 24px;
          }
        }
      }

      .wizard-inner {
        &.p-t-24 {
          padding-top: 24px;
        }

        .wizard-form {
          max-width: 700px;

          &-input {
            max-width: 450px;
          }
        }

        .wizard-input {
          width: 668px;

          &.ant-form-item.checkbox-wizard-input {
            margin-bottom: 40px;

            &.with-alert {
              margin-bottom: 8px;
            }

            &.blue-tooltip {
              width: 438px;
              padding: 8px 12px;
              color: $alert-gray;
              background-color: $alert-blue-3;
              border: 1px solid $alert-blue-2;
              border-radius: 8px;
            }
          }

          .ant-radio-disabled + span {
            color: $main-black-1;
          }

          &-pct {
            width: 668px;
          }
        }

        .submit {
          padding-bottom: 40px;
        }
      }

      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .ant-checkbox + span {
        height: 30px;
        margin-top: 0;
        margin-bottom: auto;
        line-height: 30px;
      }

      .ant-btn-primary {
        width: 208px;
      }

      :global(.ant-space-item) {
        .fees-parameters {
          display: grid;
          gap: 16px;
        }
      }

      :global(.label-switch) {
        .ant-form-item-control-input-content {
          display: flex;
          align-items: center;
          gap: 8px;

          .col-switch-desc {
            gap: 8px;
            display: flex;

            span {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      :global(.switch-group) {
        gap: 20px;
        display: grid;
      }

      .ant-form-item-control {
        width: 438px;
      }
    }
  }

  :global(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
    margin-bottom: auto;
    margin-top: 4px;
  }
}
