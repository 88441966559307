@import "../../scss/variables/colors";

.checkbox {
  :global {
    .ant-checkbox {
      width: 24px;
      height: 24px;
    }

    .ant-checkbox-input {
      height: 24px !important;
      width: 24px !important;
    }

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;

      &::after {
        width: 8px;
        height: 14px;
      }
    }
  }
}

.coloredLabel {
  color: $text-red;
  margin-bottom: 16px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(68vh);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
}

.spinContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
